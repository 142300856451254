import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loged: false,
    userName: null,
    privilege: 0,
    userData: {},
    langStore: ["de", "en", "sr"],
    nameOfLang: {
      de: "German",
      en: "English",
      sr: "Serbian"
    },
    blogStatuses: [
      { label: 'DRAFT', id: 1 },
      { label: 'PUBLISHED', id: 2 },
      { label: 'DELETED', id: 3 }
    ],
    commentsView: [
      { label: "view and children", id: 1 },
      { label: "view parents", id: 2 },
      { label: "view children", id: 3 },
    ],
    commentsStatuses: [
      { label: "NEW", id: 1 },
      { label: "APPROVED", id: 2 },
      { label: "ALL", id: 3 },
      { label: "DELETED", id: 4 },
    ],
    landing_page_url: null

  },
  mutations: {
    changeLoged(state, payload) {
      state.loged = payload
    },
    setUserName(state, payload) {
      state.userName = payload
    },
    setUserPrivilege(state, payload) {
      state.privilege = payload
    },
    setuserData(state, payload) {
      state.userData = payload
    },
    langStore(state, payload) {
      state.langStore = payload
    },
    set_landing_page_url(state, payload) {
      state.landing_page_url = payload
    }

  },
  actions: {
    lang_store(state, payload) {
      let help = []
      for (let i = 0; i < payload.length; i++) {
        help.push(payload[i].substring(2, 4))
        // console.log(help)
      }
      state.commit('langStore', help)
    }
  },
  modules: {
  },
  getters: {
    getLoged: state => {
      return state.loged
    },
    getuserData: state => {
      return state.userData
    },
    getLangStore: state => {
      return state.langStore
    },
    getBlogStatuses: state => {
      return state.blogStatuses
    },
    getNameOfLang: state => {
      return state.nameOfLang
    },
    getCommentsView: state => {
      return state.commentsView
    },
    getCommentsStatuses: state => {
      return state.commentsStatuses
    },
    getLandingPageUrl: state => {
      return state.landing_page_url
    }

  }
})
