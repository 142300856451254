<template>
    <div class="wrappLogin" @click="showContent = !showContent">
        <span class="loginImg">

            <span class="imgRoundWrapp">
                <img class="imgRound" :src="production ?
                    path_prefix.slice(0, -1).concat(require('@/assets/images/icons/user.svg')) :
                    require('@/assets/images/icons/user.svg')"></span>
            <span class="arrowRoundWrapp"><img :src="production ?
                path_prefix.slice(0, -1).concat(getArrowGR) :
                getArrowGR"></span>
        </span><!--loginImg -->


        <div v-if="showContent && (getLoged || setSid)" class="loginContent" style="width:193px">
            <router-link :to="{ name: 'profile', params: { lang: $i18n.locale } }" class="div">
                <span>{{ $t('profil.presonalInfo') }}</span>
                <span><img class="arrow" :src="production ?
                    path_prefix.slice(0, -1).concat(getArrow) :
                    getArrow"></span>
            </router-link>
            <router-link v-if="getuserData.usr_privilege === 1" :to="{ name: 'admin', params: { lang: $i18n.locale } }"
                class="div">
                <span>Admin</span>
                <span><img class="arrow" :src="production ?
                    path_prefix.slice(0, -1).concat(getArrow) :
                    getArrow"></span>
            </router-link>

            <div class="div" @click="logout">
                <span>{{ $t('log.logOut') }}</span>
                <span><img class="arrow" :src="production ?
                    path_prefix.slice(0, -1).concat(getArrow) :
                    getArrow"></span>
            </div>
        </div><!-- loginContent2 -->

        <div v-else-if="showContent" class="loginContent">
            <router-link :to="{ name: 'login', params: { lang: $i18n.locale } }" class="div">
                <span>{{ $t('log.logIn') }}</span>
                <span><img class="arrow" :src="production ?
                    path_prefix.slice(0, -1).concat(getArrow) :
                    getArrow"></span>
            </router-link>

            <router-link :to="{ name: 'signin', params: { lang: $i18n.locale } }" class="div">
                <span>{{ $t('sign.signUp') }}</span>
                <span><img class="arrow" :src="production ?
                    path_prefix.slice(0, -1).concat(getArrow) :
                    getArrow"></span>
            </router-link>
        </div>




    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { baseURL } from '@/api'

export default {
    name: "headerLogin",
    props: ['close'],
    data() {
        return {
            showContent: false,
            showImage: true,
            setSid: false,
            production: false,
            path_prefix: null
        }
    },
    mounted() {
        this.setSid = localStorage.getItem('sid')
        this.production = process.env.NODE_ENV === 'production'
        this.path_prefix = window.__blog_path_prefix
    },
    methods: {
        logout() {
            localStorage.removeItem('sid')
            localStorage.removeItem('userName')
            this.setSid = false
            this.$store.commit('setuserData', { name: '', email: '', img: '' })
            this.$store.commit('changeLoged', false)
            this.$store.commit('setUserName', null)
            this.$store.commit('setUserPrivilege', 0)

            if (this.$route.name !== "blog"
                && this.$route.name !== 'singleBlog'
            ) {
                this.$router.push({ name: 'blog' })
            }
            // if(this.$route.name === "admin") {
            //     this.$router.push({ name: "blog"})
            // }
        },
        getMainImage() {
            return baseURL + this.getuserData.image
        },

    },
    watch: {
        'close': {
            handler() {
                this.showContent = false
            }
            //   ,
            //   deep: true,
            //   immediate: true
        },
        getuserData: {
            handler() {
                this.showImage = false
                setTimeout(() => {
                    this.showImage = true
                }, 200);

            },
            deep: true,
            immediate: true
        }
    },
    computed: {
        ...mapGetters(['getLoged', 'getuserData']),
        getArrow() {
            return require('../../assets/images/loginArrow.svg')
        },
        getArrowGR() {
            return require('../../assets/images/loginArrowGreenBack.svg')
        }
    }

}
</script>

<style lang="scss" scoped>
.wrappLogin {
    margin-right: 20px;
    margin-left: 20px;
    position: relative;
    width: 35px;
    cursor: pointer;

    .loginImg {
        display: flex;
        // height: 22px;
        justify-content: space-between !important;
        // width: 100%;

        .imgRoundWrapp {
            width: 23px;
            height: 22px;

            .imgRound {
                object-fit: cover;
                border-radius: 50%;
                height: 100%;
                width: 100%;
            }

        }

        .arrowRoundWrapp {
            width: fit-content;
            height: 20px;
        }
    }


    .loginContent {
        position: absolute;
        width: 154px;
        min-height: 55px;
        right: -10px;
        top: 39px;
        z-index: 1000;
        background: var(--main-background-color);
        /* analytic container shadow */
        box-shadow: 0px 0px 5px var(--border-color);
        border-radius: 5px;
        padding: 6px 11px 7px 10px;

        .div {
            text-decoration: none;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 26px;
            display: flex;
            align-items: center;
            text-align: center;
            color: var(--text-color);
            box-sizing: border-box;
            //height: calc(50% - 7px);
            height: 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .arrow {
                transform: rotate(-90deg);
            }
        }

    }
}

@media only screen and (max-width: 768px) {
    .wrappLogin {
        .loginContent {
            right: 0px;
        }

    }
}
</style>