<template lang="html">
  <div class="create-wrapper">
    <div class="tabs">
      <!-- <span :class="{ active : active === 'createBlog' }" @click="active = 'createBlog'">Blog</span> -->
      <h2>{{ $t('admin.create.createBlog') }}</h2>
    </div>
    <keep-alive>
      <component :is="getActiveComponent" />
    </keep-alive>
  </div>
</template>

<script>
import createBlog from '@/components/admin/createBlog.vue'
export default {
  name: 'Create',
  components: {
    createBlog
  },
  data () {
    return {
      active: 'createBlog'
    }
  },
  computed: {
    getActiveComponent () {
      return this.active
    }
  }
}
</script>

<style lang="scss" scoped>
.create-wrapper {
  text-align: left;
  .tabs {
    margin-bottom: 20px;
    span {
      margin-right: 20px;
      cursor: pointer;
    }
    span:last-child {
      margin-right: 0px;
    }
    span.active {
      color: var(--main-color);
    }
  }
}
</style>
