var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer-wrap"},[_c('div',{staticClass:"footer"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"top-grp logo-grp"},[_c('img',{attrs:{"src":_vm.production ? 
            _vm.path_prefix.slice(0, -1).concat(require('../assets/images/icons/logo-no-text.svg')):
            require('../assets/images/icons/logo-no-text.svg'),"alt":""}}),_vm._m(0)]),_c('a',{attrs:{"href":"tel:+381 63 8 654 597"}},[_c('div',{staticClass:"top-grp"},[_c('img',{staticClass:"icon",attrs:{"src":_vm.production ? 
            _vm.path_prefix.slice(0, -1).concat(require('../assets/images/icons/phone.svg')):
            require('../assets/images/icons/phone.svg'),"alt":""}}),_c('span',[_vm._v("+381 63 8654 597")])])]),_c('a',{attrs:{"href":"mailto:ljubica.jovanovic.coach@gmail.com"}},[_c('div',{staticClass:"top-grp"},[_c('img',{staticClass:"icon",attrs:{"src":_vm.production ? 
            _vm.path_prefix.slice(0, -1).concat(require('../assets/images/icons/mail.svg')):
            require('../assets/images/icons/mail.svg'),"alt":""}}),_c('span',[_vm._v("ljubica.jovanovic.coach@gmail.com")])])]),_c('div',{staticClass:"top-grp"},[_c('a',{attrs:{"href":"https://www.instagram.com/ljubica_jovanovic_coach/","target":"_blank"}},[_c('img',{staticClass:"icon-soc",attrs:{"src":_vm.production ? 
              _vm.path_prefix.slice(0, -1).concat(require('../assets/images/icons/ig.svg')):
              require('../assets/images/icons/ig.svg'),"alt":""}})]),_c('a',{attrs:{"href":"https://www.facebook.com/profile.php?id=100005973528422","target":"_blank"}},[_c('img',{staticClass:"icon-soc",attrs:{"src":_vm.production ? 
            _vm.path_prefix.slice(0, -1).concat(require('../assets/images/icons/fb.svg')):
            require('../assets/images/icons/fb.svg'),"alt":""}})]),_c('a',{attrs:{"href":"https://rs.linkedin.com/in/ljubica-jovanovic-b8a445124?trk=people-guest_people_search-card&original_referer=https%3A%2F%2Fwww.linkedin.com%2F","target":"_blank"}},[_c('img',{staticClass:"icon-soc",attrs:{"src":_vm.production ? 
            _vm.path_prefix.slice(0, -1).concat(require('../assets/images/icons/in.svg')):
            require('../assets/images/icons/in.svg'),"alt":""}})]),_c('a',{attrs:{"href":"https://www.youtube.com/@mentaltennis/featured","target":"_blank"}},[_c('img',{staticClass:"icon-soc",attrs:{"src":_vm.production ? 
            _vm.path_prefix.slice(0, -1).concat(require('../assets/images/icons/yt.svg')):
            require('../assets/images/icons/yt.svg'),"alt":""}})])])]),_c('div',{staticClass:"bot"},[_c('span',[_vm._v("Created by ENON Solutions")]),_c('img',{staticClass:"bot-logo",attrs:{"src":_vm.production ? 
            _vm.path_prefix.slice(0, -1).concat(require('../assets/images/icons/enon-logo.svg')):
            require('../assets/images/icons/enon-logo.svg'),"alt":""}}),_c('span',{attrs:{"id":"footer-rights"}},[_vm._v("© 2023 Ljubica Jovanović. All rights reserved. ")]),_c('img',{staticClass:"bot-logo",attrs:{"src":_vm.production ? 
            _vm.path_prefix.slice(0, -1).concat(require('../assets/images/icons/mars-logo.svg')):
            require('../assets/images/icons/mars-logo.svg'),"alt":""}}),_c('span',[_vm._v("Powered by MARS")])]),_c('div',{staticClass:"bot-mobile"},[_c('div',{staticClass:"enon"},[_c('span',[_vm._v("Created by ENON Solutions.")]),_c('img',{staticClass:"enonlogo",attrs:{"src":_vm.production ? 
            _vm.path_prefix.slice(0, -1).concat(require('../assets/images/icons/enon-logo.svg')):
            require('../assets/images/icons/enon-logo.svg'),"alt":""}})]),_c('p',{attrs:{"id":"rights"}},[_vm._v("© 2023 Ljubica Jovanović. All rights reserved.")]),_c('div',{staticClass:"mars"},[_c('span',[_vm._v(" Powered by MARS. ")]),_c('img',{staticClass:"marslogo",attrs:{"src":_vm.production ? 
            _vm.path_prefix.slice(0, -1).concat(require('../assets/images/icons/mars-logo.svg')):
            require('../assets/images/icons/mars-logo.svg'),"alt":""}})])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"to":"/"}},[_c('span',{staticClass:"logo-text"},[_vm._v("Ljubica Jovanović")])])}]

export { render, staticRenderFns }