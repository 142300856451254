<template lang="html">
  <div
    id="index"
    class="background"
  >
    <div class="header-wrapper">
      <div class="logo-wrapper">
        <a :href="setAbsoluteLink()" >
          <img 
          id="bigLogo" 
          :src="production ? 
            path_prefix.slice(0, -1).concat(require('../../assets/images/icons/logo-main.svg')):
            require('../../assets/images/icons/logo-main.svg')"
          />
          <!-- <img :src="logo ? showImg(logo) : showImg(smallLogo)" > -->
          <!-- <img id="smallLogo" v-if="smallLogo || logo" :src="smallLogo ? showImg(smallLogo) : showImg(logo)" alt="logo">
          <img id="bigLogo" v-if="smallLogo || logo" :src="logo ? showImg(logo) : showImg(smallLogo)" alt="logo text"> -->
        </a>
      </div>

      <div class="middle">
        <a :href="setAbsoluteLink()" >{{ $t("nav.home") }} </a>
        <a :href="setAbsoluteLink(true)" >{{ $t("nav.services") }} </a>
        <router-link :class="{active:active}" :to="{ name:'blog', params: { lang: $i18n.locale } }">
          {{ $t("main.maintitle") }}
        </router-link>
        <!-- <a :class="{active:active}">{{ $t("main.maintitle") }}</a> -->
      </div>
  <div style="display:flex; align-items: center;">
      <div class="rightSideHeader">
        <div class="right">
          <a
          href="https://www.instagram.com/ljubica_jovanovic_coach/"
          target="_blank"
          ><img  
          :src="
            production ? 
            path_prefix.slice(0, -1).concat(require('../../assets/images/icons/ig.svg')):
            require('../../assets/images/icons/ig.svg')"
            alt="" />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=100005973528422"
          target="_blank"
        >
          <img 
           :src="
            production ? 
            path_prefix.slice(0, -1).concat(require('../../assets/images/icons/fb.svg')):
            require('../../assets/images/icons/fb.svg')"
            alt="" />
        </a>
        <a
          href="https://rs.linkedin.com/in/ljubica-jovanovic-b8a445124?trk=people-guest_people_search-card&original_referer=https%3A%2F%2Fwww.linkedin.com%2F"
          target="_blank"><img
          :src="
            production ? 
            path_prefix.slice(0, -1).concat(require('../../assets/images/icons/in.svg')):
            require('../../assets/images/icons/in.svg')"
          alt="" /></a>
        <a
          href="https://www.youtube.com/@mentaltennis/featured"
          target="_blank"
          ><img 
          :src="
            production ? 
            path_prefix.slice(0, -1).concat(require('../../assets/images/icons/yt.svg')):
            require('../../assets/images/icons/yt.svg')"
          alt="" /></a>
        </div>
        <LocalePicker />
        
      </div>
      <div v-if="getLoged" 
          class="wrappProfileHeader" 
          v-click-outside="closeControl">
            <headerLogin  v-if="!sampleReport" :green="green" :orange="orange" :close="close" />
      </div>
      <div class="burger-menu" @click="openSide">
        <img 
          :src="
            production ? 
            path_prefix.slice(0, -1).concat(require('../../assets/images/icons/burger.svg')):
            require('../../assets/images/icons/burger.svg')"
         />
      </div>
  </div>
    </div>
    <div v-if="true"  id="mySideBar" class="SideBar">
<!--  -->
    <div class="top">
          <div class="logo-wrap">
            <a :href="setAbsoluteLink()" >
              <img :src="logo ? showImg(logo) : showImg(smallLogo)" >
            </a>
          </div>
          <img
            class="close"
            :src="
              production ? 
              path_prefix.slice(0, -1).concat(require('../../assets/images/close.svg')):
              require('../../assets/images/close.svg')"
            alt=""
            @click="closeSide"
          />
        </div>
<!--  -->
      <div class="middle">
        <a :href="setAbsoluteLink()" >{{ $t("nav.home") }} </a>
        <a :href="setAbsoluteLink(true)" >{{ $t("nav.services") }} </a>
        <router-link :class="{active:active}" :to="{ name:'blog', params: { lang: $i18n.locale } }">
          {{ $t("main.maintitle") }}
        </router-link>
        <!-- <a :class="{active:active}">{{ $t("main.maintitle") }}</a> -->
        <div class="wrpLocale">
          <LocalePicker />
        </div>
        
      </div>
      <div class="right">
          <a
          href="https://www.instagram.com/ljubica_jovanovic_coach/"
          target="_blank"
          ><img  
          :src="
            production ? 
            path_prefix.slice(0, -1).concat(require('../../assets/images/icons/ig.svg')):
            require('../../assets/images/icons/ig.svg')"
            alt="" />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=100005973528422"
          target="_blank"
        >
        <img 
           :src="
            production ? 
            path_prefix.slice(0, -1).concat(require('../../assets/images/icons/fb.svg')):
            require('../../assets/images/icons/fb.svg')"
            alt="" />
        </a>
        <a
          href="https://rs.linkedin.com/in/ljubica-jovanovic-b8a445124?trk=people-guest_people_search-card&original_referer=https%3A%2F%2Fwww.linkedin.com%2F"
          target="_blank"
        ><img
          :src="
            production ? 
            path_prefix.slice(0, -1).concat(require('../../assets/images/icons/in.svg')):
            require('../../assets/images/icons/in.svg')"
          alt="" /></a>
        <a
          href="https://www.youtube.com/@mentaltennis/featured"
          target="_blank"
        ><img 
          :src="
            production ? 
            path_prefix.slice(0, -1).concat(require('../../assets/images/icons/yt.svg')):
            require('../../assets/images/icons/yt.svg')"
          alt="" /></a>
        </div>
    </div>
  </div>
</template>

<script>
import headerLogin from '@/components/loginComponents/headerLogin'
import LocalePicker from '../LocalePicker.vue'
import {  baseURLimage } from '../../api'
import { mapGetters } from 'vuex'
export default {
  name: 'appHeader',
  data () {
    return {
      green: true,
      orange: false,
      close: false,
      production: false,
      path_prefix: null,
      setSid: false,
      active: true,
      showSideBar: false,
    }
  },
  props: {
    logo: {

    },
    smallLogo: {

    },
    sampleReport: {
      default: false
    }
  },
  components: {
    headerLogin,
    LocalePicker
  },
  mounted() {
        this.setSid= localStorage.getItem('sid')
        this.production = process.env.NODE_ENV === 'production'
        this.path_prefix = window.__blog_path_prefix

  },
  methods: {
    scrollToHowItWorks () {
      if (this.$route.name !== 'Home') {
        this.$router.push({ path: '/', hash: 'scrollToHowItWorks' })
      } else {
        let el = document.querySelector('.expWrapp')
        el.scrollIntoView({behavior: "smooth"});
      }
    },
    navigateHome () {
      if (this.$route.path !== '/') {
        this.$router.push('/')
      }
    },
    navigateToBlogList () {
      this.$router.push('/blog')
    },
    navigateSteps () {
      this.$router.push('/priceSteps')
    },

    closeControl () {
      this.close = !this.close
    },
    closeSide () {
      console.log('close')
      this.showSideBar = false
      if(document.getElementsByClassName("SideBar") && document.getElementsByClassName("SideBar").length > 0)
      document.getElementsByClassName("SideBar")[0].style.width = "0%";
      // document.getElementsByClassName("SideBar")[0].style.display = "none";
      
    },
    openSide () {
      console.log('open')
      this.showSideBar = true
      document.getElementsByClassName("SideBar")[0].style.width = "100%";
      // document.getElementsByClassName("SideBar")[0].style.display = "block";
    },
    showImg (img) {
      if(this.production){
        return img
      }
      return baseURLimage  + img
    },
    setAbsoluteLink(services = false) {
      if(services) {
        if(process.env.NODE_ENV === 'production' || this.getLandingPageUrl){ 
          // return process.env.VUE_APP_SERVICES_LINK + "?l="+this.$i18n.locale
          return this.getLandingPageUrl + "services?l="+this.$i18n.locale
        }  else {
          return process.env.VUE_APP_SERVICES_LINK_DEV + "?l="+this.$i18n.locale
        }
        // return "http://113x123.mars2.mars-hosting.com/services?l="+ this.$i18n.locale
      } else{
        if(process.env.NODE_ENV === 'production'|| this.getLandingPageUrl){ 
          // return process.env.VUE_APP_HOME_LINK + "?l="+this.$i18n.locale
          return this.getLandingPageUrl.slice(0, -1) + "?l="+this.$i18n.locale
        }  else {
                // __landing_page_url
          return process.env.VUE_APP_HOME_LINK_DEV + "?l="+this.$i18n.locale
        }
        // return "http://113x123.mars2.mars-hosting.com?l="+ this.$i18n.locale
      }
    }
  },
  watch: {
   '$route': {
      handler () {
      //   console.log(!this.$store.state.langStore.find(el=>el===newVAl))
      //   if(!this.$store.state.langStore.find(el=>el===newVAl)) {
      //     this.$route.params.lang = 
      //     this.$store.state.langStore.find(el=>el===oldVAL) ? oldVAL : 'en'
      //   }
      //   console.log(this.$route.params.lang)

      if(this.$route.name === 'blog'){
        this.active = true
      }else{
        this.active = false
      }
      this.closeSide()
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['getLoged', 'getLandingPageUrl'])
   }
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
#index {
  position: relative;
}
.sample-report-wrapper {
  background: var(--header-background-color);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  z-index:110;


  @keyframes example {
    from {right: -200px;}
    to {right: 2760px;}
  }

  .sample-report {
    width: auto;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-color);
    cursor: default;
    position: absolute;
    animation-duration: 40s;
    animation-timing-function: linear;
    animation-name: example;
    animation-iteration-count: infinite;
    white-space: nowrap;
    animation-fill-mode: forwards;
    right: -200px;
  }
}

.header-wrapper {
  // z-index: 1002;
  height: 100px;
  width: 100%;
  padding: 0 30px;
  max-width: calc(1440px + 60px);
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  

   .middle {
      a {
        font-size: 1.2rem;
        margin: 1rem;
        padding-bottom: 5px;
        font-weight: 500;
        text-decoration: none;
      }
      a:link {
        color: var(--text-color);
      }
      a:visited  {
        color: var(--text-color);
      }
      a:hover  {
        color: var(--text-color);
      }
      // a:active{
      //   border-bottom: 5px solid var(--btn-color);
      // }

      .active {
        border-bottom: 5px solid var(--btn-color);
      }
    }
    

  span {
    cursor: pointer;
    color: #4C4C52;
    margin-top: 8px;
  }
  #value-my-car {
    margin-left: 25px;
  }
  #blog {
    margin-left: 25px;
  }
  @media only screen and (max-width: 768px) {
    #how-it-works, #blog {
      display: none;
    }
  }
 
  .logo-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    #smallLogo {
      margin-top:-2px;
      width: 30px;
      display: none;
    }
    #bigLogo {
      width:120px;
      //height:25px;
      display: block;
    }
  }
  .rightSideHeader{
    display: flex;
    .right {
      a {
        cursor: pointer;
      }

      img {
        margin-right: 25px;
      }
    }
  }
  .wrappProfileHeader{
     height: fit-content;
    display: flex;
    align-items: center;
  }

  .burger-menu {
    display: none;
  }
  
@media only screen and (width <= 860px) {
  .middle,
  .rightSideHeader,
  .right {
    display: none;
  }


  .burger-menu {
    display: block !important;
    img {
        width: 40px;
        height: 40px;
        margin-left: 10px;
      }
  }
}
  

  @media only screen and (max-width: 450px) {
    // .logo-wrapper #smallLogo {
    //   display: block;
    // }
    // .logo-wrapper #bigLogo {
    //   display: none;
    // }
  }
}
 .background{
   position: relative;
   z-index:210 !important;
   background-color: var(--header-background-color);
 }

 .SideBar{
    position: fixed;
    top:0;
    left:0;
    width: 0%;
    height: 100vh;
    box-sizing: border-box;
    padding-bottom: 20px;
    background: var(--main-background-color);
    border-bottom: 2px solid var(--border-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    transition: width .5s;
    z-index: 1015 !important;
    
    .top {
      // height: $nav-height;
      max-width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding:20px;

      .logo {
        height: 60px;
      }

      .close {
        width: 30px;
        height: 30px;
      }
    }

    .middle{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;

      a {
        font-size: 1.6rem;
        margin: 1rem;
        padding-bottom: 5px;
        font-weight: 500;
        text-decoration: none;
      }
      a:link {
        color: var(--text-color);
      }
      a:visited  {
        color: var(--text-color);
      }
      a:hover  {
        color: var(--text-color);
      }
      // a:active{
      //   border-bottom: 5px solid var(--btn-color);
      // }

      .active {
        border-bottom: 5px solid var(--btn-color);
      }
      .wrpLocale{
        margin:16px;
      }
    }
    .right{
      margin:30px 0;
      display: flex;
      justify-content: center;
      // scale: 1.25;
      img{
        margin:20px;
        width:30px;
        
      }

    }
    
  }
@media only screen and (max-width: 450px) {
  .disappearing {
    display: none;
  }
  .header-wrapper {
    padding: 0 16px;
  }
}
</style>
<style lang="scss">
.middle{ 
    .wrpLocale{
      // scale: 1.2 !important;
      transform: scale(1.3);

  }
}

</style>
