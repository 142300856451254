<template >
  <div class="go-top-wrapper">
    <div class="wrapImgTop"> 
        <div class="img" @click="scrollToTop" >
          <!-- <img  
            :src="
            production ? 
            path_prefix.slice(0, -1).concat(require('../assets/images/goTop.svg')):
            require('../assets/images/goTop.svg')"
            alt="go to top"> -->
            <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 9L8 3L14 9" stroke="white" stroke-width="4"/>
            </svg>
        </div>
    </div> 
  </div>
</template>
  
  <script>
  export default {
    name: 'goTop',
    data() {
      return{
        production: false,
        path_prefix: null

      }
    },
    created (){
      this.production = process.env.NODE_ENV === 'production'
      this.path_prefix = window.__blog_path_prefix
    },
    methods: {
      scrollToTop () {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .go-top-wrapper {
     z-index: 2000;
     position:relative;
   
    @keyframes pulse {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 var(--btn-color);
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
        }

        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
    }
    .wrapImgTop{
      height: 42px;
      width: 42px;
      border-radius: 50%;
      position: fixed;
      bottom: 80px;
      // right: 30px;
      // right: 20px;
      right: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      box-shadow: 0 0 0 0 var(--btn-color);
      transform: scale(1);
      animation: pulse 2s infinite;
      background: var(--btn-color);
    }
    .img {
      background: var(--btn-color);
      cursor: pointer;
      height: 42px;
      width: 42px;
      border-radius: 50%;
      z-index: 1001;
     
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        // width: 38px;
        // height: 38px;
      }
      svg{
        path{
          stroke: var(--main-background-color);
        }
      }
    }
    .img:hover {
      filter: brightness(0.96);
      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
      transition-duration: .9s;
      z-index: 1001;
    }
  }
  @media only screen and (max-width: 768px){
    .go-top-wrapper {
    }
  }
  </style>
  