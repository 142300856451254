<template lang="html">
  <div class="single-blog-details-wrapper">
    <!-- <div class="line"></div> -->
    <div class="wrapp">
      <div class="left">
        <span class="date">{{setD}}</span>
        <span class="time">{{blog.readTime}} minutes read</span>
      </div>
      <!-- <div class="right">
        <span class="written-by">Written by</span>
        <span class="author">{{blog.author}}</span>
      </div> -->
    </div>
    <div class="share-wrapper">
      <span class="share">Share post</span>
      <div class="share-icons">
        <!-- <img :src="require('@/assets/images/fb.svg')" alt="facebook icon">
        <img :src="require('@/assets/images/linkedin.svg')" alt="linkedin icon">
        <img :src="require('@/assets/images/instagram.svg')" alt="instagram icon"> -->
        <shareIcon
          :titleP='blog.title'
          :descriptionP='blog.description'
          :author='blog.author'
          :isblog='true'
          :hashTags="hashtags"
          @showmodal='showmodal'
        />
      </div>
    </div>
    <div class="claps" v-if="showBClaps">
        <vue-clap-button 
          :colorActive="setColor? setColor:'#ff5754a1'"
          :bgColor="bgColor"
          :size="size"
          icon="star"
          :maxClick="maxClap"
          :initClicked="blog.user_likes"
          @clap="clap"
          @cancel="cancelClap"
       />
       <div class="showClaps" >{{ showClaps }} </div>
    </div>
  </div>
</template>

<script>
import shareIcon from '@/components/shareComponents/ShareIcon.vue'
import helperDate from '@/js/helperDate.js'
import { api } from '@/api'
export default {
  name: 'singleBlogDetails',
  props: {
    blog: {
      required: true,
    }
  },
  data () {
    return {
      // clasp data
        size: 40,
        supportsPassive : false,

        maxClap: 5,
        totalClaps:300,
        showClaps: 0,
        userClap: 0,

        setColor:null,
        bgColor: null,

      // real data
      // setDate: null
         date: null,
         click:0,
         showBClaps: true,
         hashtags:''

     }
  },
  components: {
    shareIcon
  },
  created() {
    // try {
    //   window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
    //     get:  this.supportsPassive = true  
    //   }));
    // } catch(e){
    //   console.log(e)
    // }
    let config = JSON.parse(localStorage.getItem('config'))
    if( config && config['max_likes']) {
      this.maxClap = Number(config['max_likes']) 
    }
  },
  mounted(){
    this.setInitialClaps(this.blog.total_likes, this.blog.user_likes)
    this.setColor = localStorage.getItem('clapColor')
    // this.bgColor = localStorage.getItem('bgColor')

    // this.setDate = helperDate.formatDate(this.blog.date)
    this.hashtags = this.setHashtag(this.blog.categories)
    
  },
  methods: {
    clap(e){
     
      this.click+=1
     
      // reset value 
      this.showClaps = (e + this.blog.total_likes) - this.blog.user_likes
      let params={
        link: this.blog.link,
        count: e //+ this.blog.user_likes - comment because initial like is returned in e
      }

      this.date=Date.now()
      setTimeout(()=>{

        if(this.date + 1500 <= Date.now()) {
          // console.log('da')
           api.addLikes(params).then(res=> {
            console.log(res)
          //  this.$emit('refresh', true)
          }).catch( e => {
            console.log(e)
            this.$emit('refresh', true)
          })
        }else {
          // console.log('ne - ')
        }
      }, 1500)
    },
    cancelClap(e) {
       console.log(e)
      this.showClaps = this.blog.total_likes - this.blog.user_likes
      api.deleteLikes(this.blog.link).then(res =>{
        console.log(res)
      //  this.$emit('refresh', true)
      }).catch(e => {
        console.log(e)
        this.$emit('refresh', true)
      })
    },
    setInitialClaps(e, likes){
       this.showClaps = e ? e : 0
       this.userClap = likes
    },
    showmodal(e) {
     this.$emit('showmodal', e)
    },
    preventDefault(e) {
      e.preventDefault()
    },
    preventDefaultForScrollKeys(e) {
      var keys = {37: 1, 38: 1, 39: 1, 40: 1}
      if (keys[e.keyCode]) {
           this.preventDefault(e)
           return false
      }
    },
    setHashtag(category){
      console.log(category)
        let stringSet = ''
        if (category !== undefined && category !== null && category.length > 0) {
            for (let i = 0; i < category.length; i++) {
                console.log(category[i].cat_name)
                if (category[i].cat_name !== null) {
                    if (i === category.length - 1)
                        stringSet += category[i].cat_name.replace(' ', '')
                    else
                        stringSet += category[i].cat_name.replace(' ', '') + ','
                }
            }
        }
        return stringSet
    }
  },
  watch: {
    blog: {
      handler(newVal) {
        this.showBClaps= false
        // console.log(newVal)
        this.setInitialClaps(newVal.total_likes, newVal.user_likes)
        setTimeout(() => {
          this.showBClaps= true
        }, 100);
      }, deep: true
    }
  },
  computed: {
    setD (){
      return helperDate.formatDate(this.blog.date)
    }
  
  }
}
</script>

<style lang="scss" scoped>
.single-blog-details-wrapper {
  display: flex;
  flex-direction: column;
  .line {
    width: 30px;
    height: 2px;
    background: var(--border-color);
    margin-bottom: 15px;
  }
  .wrapp {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .left {
    display: flex;
    flex-direction: column;
    flex-direction: row;
    justify-content: space-between;
    // margin-bottom: 22px;
    width: 50%;
    min-width: 300px;
    border-top: 1px solid var(--border-color);
    padding: 10px 0px;
    padding-top: 12px;
    text-align: left;
    .date {
      font-size: 15px;
      line-height: 22px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 25px;
      color: var(--text-soft-color);
      // margin-bottom: 5px;
    }
    .time {
      font-size: 13px;
      line-height: 22px;

      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 25px;
      color: var(--text-soft-color);
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    margin-bottom: 22px;
    min-width: 205px;
    .written-by {
      font-size: 13px;
      line-height: 24px;
      color: var(--text-soft-color);
    }
    .author {
      font-size: 15px;
      line-height: 24px;
      color: var(--text-soft-color);
    }
  }
  .share-wrapper {
    width: 50%;
    border-top: 1px solid var(--border-color);
    padding: 10px 0px;
    min-width: 300px;
    text-align: left;
    .share {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 25px;
      color: var(--text-color);
    }
    .share-icons {
      margin-top: 10px;
      img {
        cursor: pointer;
        margin-right: 30px;
        max-height: 12px;
        max-width: 12px;
      }
      img:last-child {
        margin-right: 0px;
      }
    }
  }
  .claps{
     box-sizing: border-box;
     border-top: 1px solid var(--border-color);
     width: 300px;
     height: 71px;
     padding: 10px 0;
     padding-top: 20px;
     display: flex;
     align-items: center;

     .showClaps{
       margin-left: 25px;
     }

  }
}
@media only screen and (max-width: 1200px){
  .single-blog-details-wrapper {
    .left, .share-wrapper {
      width: 100%;
      
    }
    .claps{
        width: 100%;
    }
  }
}
@media only screen and (max-width: 450px){
  .single-blog-details-wrapper {
    .left, .share-wrapper {
      width: 100%;
      min-width: 1px;
    }
  }
}
</style>
<style>
.vclapbtn-bin{
  margin: 0 !important;
}</style>
