<template lang="html">
  <div :class="{ blogPage : blogPage }" class="home-blog-wrapper">
    <div v-if="blogPage" class="blog-title">
      <router-link to="/blog">
        <span>{{$t('main.related')}}</span>
      </router-link>
    </div>
    <div v-else class="home-blog-title">
      <span>DISCOVER ON OUR BLOG</span>
    </div>
    <div class="content-wrapper">
      <blogAd v-for="(blog, index) in blogs" :key="index" :blogData="blog" />
    </div>
    <div class="btn-wrapper">
      <router-link to="/blog">
        <div class="view-all-btn">
         {{ $t('main.viewAll') }}
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { api } from '@/api'
import blogAd from '@/components/blog/blogAd.vue'
export default {
  name: "homeBlogWrapper",
  components: {
    blogAd
  },
  props: {
    blogPage: {
      required: false,
      default: false
    },
    blogId: {
      required: false
    },
    category: {

    }
  },
  data () {
    return {
      blogs: []
    }
  },
  mounted () {
    this.getBlogs()
  },
  methods: {
    navigateToBlogList () {
      this.$router.push('/blog')
    },
    getBlogs () {
      let params = {
        count: 3,
        offset: 0,
        locale: this.$i18n.locale,
      }
      if (this.blogPage && this.blogId) {
        params.id = this.blogId
      }
      if (this.category) {
        params.category= JSON.stringify(this.category)
      }
      api.getBlogs(params).then(res => {
        this.blogs = res.data.data
      }).catch(e => {
        console.log(e);
      })
    }
  },
  watch: {
    blogId: function () {
      this.getBlogs()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/css/buttonStyle.scss';
.home-blog-wrapper {
  width: calc(100% - 32px);
 
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  padding-bottom: 90px;

  a {
    text-decoration: none;
  }

  .btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

  }

  .home-blog-title {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    max-width: 1278px;
    margin-bottom: 43px;
    margin-top: 29px;

    span {
      font-size: 15px;
      line-height: 36px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: var(--title-color);
    }
  }
  .blog-title {
    height: 70px;
    width: 100%;
    padding-top: 10px;
    box-sizing: border-box;
    margin-bottom: 25px;
    

    span {
      cursor: pointer;
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 60px;
      color: var(--title-color);
    }
  }
  .content-wrapper {
    max-width: 1278px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.blogPage.home-blog-wrapper {
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
}
@media only screen and (max-width: 768px){
  .home-blog-wrapper {
     padding-bottom: 121px;
    .btn-wrapper {
      display: block;
    }
    .home-blog-title {
      margin-top: 27px;
       margin-bottom: 24px;
      .view-all-btn {
        display: none;
      }
    }
  }
}
@media only screen and (max-width: 450px){
  .home-blog-wrapper {
    padding-bottom: 0px;
    padding-bottom: 81px;
     .home-blog-title {
       height: fit-content;
        margin-bottom: 26px;
        margin-top: 17px;
     }
  }
}
</style>
