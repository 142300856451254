<template>
    <div class="admnCategory">
       <h2>  {{ $t('admin.menu.category') }}</h2>

        <div v-for="(cat, index) in categories" :key="index" class="wrappInput">

            <input v-if="setEdit !== index"  type="text" v-model="categories[index].cat_name" class="readonly" readonly>
            <input v-if="setEdit === index"  type="text" v-model="categories[index].cat_name" class="input">
            <div class="wrapOptions">
                <div class="edit">
                    <div v-if="setEdit !== index" class="buttonSend" @click="setEdit=index">{{ $t('admin.create.edit') }}</div>
                    <div v-if="setEdit === index" class="buttonCancel" @click="reset(cat)" >{{ $t('admin.create.cancel') }}</div>
                    <div v-if="setEdit === index" class="buttonSend" @click="edit(cat)">{{ $t('admin.create.confirm') }}</div>
                </div>
                <div class="delete" @click="deleteCat(cat)">
                    x
                </div>
            </div>
        </div>
        <div class="wrappInput">
            
                <input  type="text" v-model="cat_name" class="input">
                <div class="wrapOptions">
                <div class="edit">
                    <div class="buttonSend" @click="create" >{{$t('admin.create.create') }}</div>
                </div>
                <div class="delete" @click="cat_name=''">
                    x
                </div>
            </div>
        </div>
       
    </div>
</template>

<script>
import { api } from '@/api'
export default {
    name: 'admnCategory',
    data() {
        return {
            oldCategories: [],
            categories: [],
            cat_name: '',
            setEdit: -1

        }
    },
    activated () {
        this.getCategories()

    },
    methods: {
        getCategories() {
            this.setEdit = -1
            api.getCategories().then(res => {
                this.categories = {}
                this.categories = res.data.data
                this.oldCategories = {}
                this.oldCategories = JSON.parse(JSON.stringify(res.data.data))

            }).catch(e => {
                console.log(e)
            })
        },
        deleteCat(e) {
            this.setEdit = -1
            let confirm = window.confirm(`Are you sure you want to delete ${e.cat_name} category?`)
            if (!confirm) {
                return
            }
           let params = e
           api.deleteCategories(params).then(res => {
            console.log(res)
            this.getCategories()
            alert('You have successfully deleted the category.')
           }).catch(e => {
            console.log(e)
            alert('Something went wrong, please try again.')
           })

        },
        edit(e) {
            console.log(e)
            let index = this.oldCategories.findIndex((obj) => obj.cat_id === e.cat_id)
            if(this.oldCategories[index].cat_name === e.cat_name) {
                alert('Nothing has changed')
                return
            }
            this.setEdit= -1
            let params = {
                name: e.cat_name,
                cat_id: e.cat_id
            }
            api.editCategory(params).then(res => {
                console.log(res)
                this.getCategories()
                alert('You have successfully changed the category.')
            }).catch(e => {
                console.log(e)
            })
        },
        create () {
            if(!this.cat_name || this.cat_name.trim().length <= 0) {
                alert('Category name cannot be empty.')
                return
            }
            api.addCategory({name: this.cat_name.trim()}).then(res => {
                console.log(res)
                this.getCategories()
                this.cat_name = ''
                alert('New category has been created.')
            }).catch(e => {
                console.log(e)
                alert('Something went wrong, please try again.')
            })


        },
        reset (e) {
            // this.getCategories()
            let indx = this.oldCategories.findIndex((obj) => obj.cat_id === e.cat_id)
            let indx2 = this.categories.findIndex((obj) => obj.cat_id === e.cat_id)
            this.categories[indx2].cat_name = this.oldCategories[indx].cat_name
            this.setEdit = -1

        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/css/buttonStyle.scss';

.wrappInput:nth-last-of-type(odd) {
   background-color: rgba(0, 0, 0, 0.2);
    // filter: brightness(0.75);

}
.wrappInput{
    display: flex;
    align-items: center;
    justify-content: center;
    .readonly{
        border: 1px solid transparent;
        margin: 5px 0;
        height: 39.48px;
        border-radius: 6px;
        outline: none;
        width: 350px;
        background-color: transparent;
        color: var(--text-color);
    }
    .input{
        margin: 5px 0;
        height: 39.48px;
        border: 1px solid var(--border-color);
        filter: drop-shadow(0px 1px 3px var(--border-color));
        border-radius: 6px;
        outline: none;
        width: 350px;
        background-color: var(--main-background-color);
        color: var(--text-color);

    }
    
    .wrapOptions{
        display: flex;
        align-items: center;
       
        .edit{
            width: 160px;
            display: flex;
        }

        .delete{
            margin-left: 30px;
            cursor: pointer;
        }
    }

    
}
.wrappButton{
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 50px;

        .buttonSend,
        .buttonCancel {
            width:40%;
        }
        
    }
</style>
