<template lang="html">
    <div v-click-outside="closeDropdown" class="dropdown-wrapper">
      <div
        :class="{ disabled: disabled }"
        @click="openDropdown"
        class="header-wrapper"
      >
        <span>
          {{ getSelected }}
        </span>
        <img
          :class="{ open: open }"
          :src="
          // production ? 
          // path_prefix.slice(0, -1).concat(require('../../assets/images/loginArrow.svg')) :
          require('../../assets/images/icons/dropdownArrowBig.png')"
          alt="dropdown arrow icon"
        />
      </div>
      <div v-if="open" class="dropdown ">
        <div v-if="list && list.length > 0" class="option-wrapper scrollbar">
          <input
            id="dropdown-search"
            v-model="search"
            type="text"
            :placeholder="$t('main.search')"
          />
          <div v-if="removable && selected" @click="remove" class="option clear">
            {{ $t('main.clear') }}
          </div>
          <template v-if="customSeach">
            <div
              :class="{ active: item === selected }"
              @click="setOption(item)"
              v-for="(item, index) in list"
              :key="index"
              class="option"
              :title="
                typeof item === 'object' && item !== null ? item.label : item
              "
            >
              {{ typeof item === 'object' && item !== null ? item.label : item }}
            </div>
          </template>
          <template v-else>
            <div
              :class="{ active: item === selected }"
              @click="setOption(item)"
              v-for="(item, index) in filteredList"
              :key="index"
              class="option"
              :title="
                typeof item === 'object' && item !== null ? item.label : item
              "
            >
              {{ typeof item === 'object' && item !== null ? item.label : item }}
            </div>
          </template>
        </div>
        <div v-else class="option-wrapper">
          <input
            id="dropdown-search"
            v-model="search"
            type="text"
            :placeholder="$t('main.search')"
          />
          <div class="option">
            {{ $t('main.noOptions') }}
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Dropdown',
    props: {
      list: {
        type: Array,
        required: false
      },
      selected: {
        required: false
      },
      defaultText: {
        type: String,
        required: false
      },
      removable: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      customSeach: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        open: false,
        search: null,
        production: false,
        path_prefix: null
       
      }
    },
    created(){
      this.production = process.env.NODE_ENV === 'production'
      this.path_prefix = window.__blog_path_prefix
    },
    methods: {
      remove (e) {
        e.preventDefault()
        e.stopPropagation()
        this.$emit('remove')
        this.closeDropdown()
      },
      closeDropdown () {
        this.open = false
        this.search = null
      },
      openDropdown () {
        if (this.disabled) {
          return
        }
        this.open = !this.open
        setTimeout(() => {
          //let el =
           document.querySelector('#dropdown-search').focus()
        }, 100)
      },
      setOption (option) {
        this.$emit('changeOption', option)
        this.closeDropdown()
      },
      typing (e) {
        console.log(e)
      }
    },
    computed: {
      getSelected () {
        if (this.selected) {
          return typeof this.selected === 'object' && this.selected !== null
            ? this.selected.label
            : this.selected
        } else if (this.defaultText) {
          return this.defaultText
        } else {
          return 'Sort...'
        }
      },
      filteredList () {
        if (this.search) {
          return this.list.filter(item => {
            return this.search
              .toLowerCase()
              .split(' ')
              .every(v => item.label.toLowerCase().includes(v))
          })
        } else {
          return this.list
        }
      }
    },
    watch: {
      search: function (val) {
        this.$emit('search', val)
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  @import '../../assets/css/scrollbarStyles.scss';
 
  .dropdown-wrapper {
    position: relative;
    // background: var(--main-background-color);
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    // width: max-content;
  
    .header-wrapper {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        // border: 1px solid #f2f2f2;
        cursor: pointer;
        // height: 39.48px;
        border: 1px solid var(--border-color);
        // filter: drop-shadow(0px 1px 3px var(--border-color));
        border-radius: 6px;
        background: var(--main-background-color);
        height: 40px;
        // overflow: auto;
    
      span {
        margin-right: 20px;
        width:100%;
        height: 100%;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        // display: block;
      }
      img {
        transition-duration: 0.3s;
      }
      img.open {
        transform: rotate(180deg);
      }
    }
    .header-wrapper.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
    .dropdown {
     
        position: absolute;
        top: calc(100% + 3px);
        background-color: var(--main-background-color);
        left: 1px;
        min-width: calc(100% - 2px);
         //box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06);
        border: 1px solid var(--border-color);
        // filter: drop-shadow(0px 1px 3px var(--border-color));
        z-index: 100;
  
      .option-wrapper {
        position: relative;
        max-height: 200px;
        max-width: 100%;
        overflow-x: hidden;
        // max-width: 80%;
        overflow: auto;
        @import '@/assets/css/scrollbarStyles.scss';
       
       
  
        .option {
          box-sizing: border-box;
          white-space: nowrap;
          height: 38px;
          line-height: 38px;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
          max-width:300px;
          display: block;
          justify-content: flex-start;
          align-items: center;
          text-align: left;
          padding-left: 10px;
          padding-right: 10px;
          cursor: pointer;
          background-color: var(--main-background-color);
        }
        .option.active {
          // background: rgba(196, 196, 196, 0.15);
          // opacity: 0.07;
          filter:  brightness(1.55);
        }
        .clear{
          color: var(--btn-color);
          text-align: center;
          height: 28px;
        }
        input {
          border: none;
          width: 100%;
          padding: 0px;
          padding-left: 10px;
          box-sizing: border-box;
          height: 38px;
          background: var(--main-background-color);
          color: var(--text-color);
        }
        input:focus {
          outline: none;
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .dropdown-wrapper {
      font-size: 0.9em;
      .dropdown {
        position: absolute;
        top: calc(100% + 3px);
        // background-color: white !important;
        left: 1px;
        //min-width: calc( 100% - 2px );
        min-width: 50%;
        width: 100%;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06);
        z-index: 1;
  
        .option-wrapper {
          position: relative;
          max-height: 200px;
          max-width: 100%;
          overflow: auto;
          @import '@/assets/css/scrollbarStyles.scss';
         
  
          .option {
            white-space: wrap;
            min-height: 38px;
            max-width: 100%;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 10px;
            padding-right: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
  </style>
  