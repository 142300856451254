<template>
    <div class="login">
       <loginComponent 
            @login="loginuser"
            @clearmsgerr="msg=''"
            :msgerr="msg" 
        />
    </div>
</template>

<script>
import { api } from '@/api'
import loginComponent from '@/components/loginComponents/loginComponent'
export default {
    name: 'login',
    components: {
        loginComponent,
    },
    data () {
        return {
            msg:'',
        }
    },
    mounted(){
    },
    methods: {
        async loginuser(e) {
            if(window.__recaptcha_key_client ||  process.env.NODE_ENV !== 'production') {
                await this.$recaptchaLoaded()
                const token = await this.$recaptcha('login').catch(() => {})
                e.token = token
            }
            api.logInUser(e).then(res => {
                localStorage.setItem('sid', res.data.data.sid)
                localStorage.setItem('usr_id', res.data.data.usr_id)
                this.$store.commit('changeLoged', true)
                this.getUserData(res.data.sid, res.data.data.usr_id) 
                // this.$router.go(-1)
                this.$router.push({ name: 'blog' })
                this.$store.commit('setUserPrivilege', res.data.data.usr_privilege)
            }).catch(e => {
             this.msg='msg.userOrPass'
             console.log(e.message);
            })

        },
        getUserData(sid, id) {
            let params = {
                sid : sid,
                id : id
            }
            api.getUserData(params).then(res => {
                this.$store.commit('setuserData', res.data.data)
            }).catch(e => {
             console.log(e)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.login{
    background-color: var(--main-background-color);
    display: flex;
    height: calc(100vh - 80px);
    justify-content: center;
    align-items: center;
    
}
@media only screen and (max-width: 500px){
    .login{
        height: initial;
    }
}
</style>
