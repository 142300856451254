var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background",attrs:{"id":"index"}},[_c('div',{staticClass:"header-wrapper"},[_c('div',{staticClass:"logo-wrapper"},[_c('a',{attrs:{"href":_vm.setAbsoluteLink()}},[_c('img',{attrs:{"id":"bigLogo","src":_vm.production ? 
            _vm.path_prefix.slice(0, -1).concat(require('../../assets/images/icons/logo-main.svg')):
            require('../../assets/images/icons/logo-main.svg')}})])]),_c('div',{staticClass:"middle"},[_c('a',{attrs:{"href":_vm.setAbsoluteLink()}},[_vm._v(_vm._s(_vm.$t("nav.home"))+" ")]),_c('a',{attrs:{"href":_vm.setAbsoluteLink(true)}},[_vm._v(_vm._s(_vm.$t("nav.services"))+" ")]),_c('router-link',{class:{active:_vm.active},attrs:{"to":{ name:'blog', params: { lang: _vm.$i18n.locale } }}},[_vm._v(" "+_vm._s(_vm.$t("main.maintitle"))+" ")])],1),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"rightSideHeader"},[_c('div',{staticClass:"right"},[_c('a',{attrs:{"href":"https://www.instagram.com/ljubica_jovanovic_coach/","target":"_blank"}},[_c('img',{attrs:{"src":_vm.production ? 
            _vm.path_prefix.slice(0, -1).concat(require('../../assets/images/icons/ig.svg')):
            require('../../assets/images/icons/ig.svg'),"alt":""}})]),_c('a',{attrs:{"href":"https://www.facebook.com/profile.php?id=100005973528422","target":"_blank"}},[_c('img',{attrs:{"src":_vm.production ? 
            _vm.path_prefix.slice(0, -1).concat(require('../../assets/images/icons/fb.svg')):
            require('../../assets/images/icons/fb.svg'),"alt":""}})]),_c('a',{attrs:{"href":"https://rs.linkedin.com/in/ljubica-jovanovic-b8a445124?trk=people-guest_people_search-card&original_referer=https%3A%2F%2Fwww.linkedin.com%2F","target":"_blank"}},[_c('img',{attrs:{"src":_vm.production ? 
            _vm.path_prefix.slice(0, -1).concat(require('../../assets/images/icons/in.svg')):
            require('../../assets/images/icons/in.svg'),"alt":""}})]),_c('a',{attrs:{"href":"https://www.youtube.com/@mentaltennis/featured","target":"_blank"}},[_c('img',{attrs:{"src":_vm.production ? 
            _vm.path_prefix.slice(0, -1).concat(require('../../assets/images/icons/yt.svg')):
            require('../../assets/images/icons/yt.svg'),"alt":""}})])]),_c('LocalePicker')],1),(_vm.getLoged)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeControl),expression:"closeControl"}],staticClass:"wrappProfileHeader"},[(!_vm.sampleReport)?_c('headerLogin',{attrs:{"green":_vm.green,"orange":_vm.orange,"close":_vm.close}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"burger-menu",on:{"click":_vm.openSide}},[_c('img',{attrs:{"src":_vm.production ? 
            _vm.path_prefix.slice(0, -1).concat(require('../../assets/images/icons/burger.svg')):
            require('../../assets/images/icons/burger.svg')}})])])]),(true)?_c('div',{staticClass:"SideBar",attrs:{"id":"mySideBar"}},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"logo-wrap"},[_c('a',{attrs:{"href":_vm.setAbsoluteLink()}},[_c('img',{attrs:{"src":_vm.logo ? _vm.showImg(_vm.logo) : _vm.showImg(_vm.smallLogo)}})])]),_c('img',{staticClass:"close",attrs:{"src":_vm.production ? 
              _vm.path_prefix.slice(0, -1).concat(require('../../assets/images/close.svg')):
              require('../../assets/images/close.svg'),"alt":""},on:{"click":_vm.closeSide}})]),_c('div',{staticClass:"middle"},[_c('a',{attrs:{"href":_vm.setAbsoluteLink()}},[_vm._v(_vm._s(_vm.$t("nav.home"))+" ")]),_c('a',{attrs:{"href":_vm.setAbsoluteLink(true)}},[_vm._v(_vm._s(_vm.$t("nav.services"))+" ")]),_c('router-link',{class:{active:_vm.active},attrs:{"to":{ name:'blog', params: { lang: _vm.$i18n.locale } }}},[_vm._v(" "+_vm._s(_vm.$t("main.maintitle"))+" ")]),_c('div',{staticClass:"wrpLocale"},[_c('LocalePicker')],1)],1),_c('div',{staticClass:"right"},[_c('a',{attrs:{"href":"https://www.instagram.com/ljubica_jovanovic_coach/","target":"_blank"}},[_c('img',{attrs:{"src":_vm.production ? 
            _vm.path_prefix.slice(0, -1).concat(require('../../assets/images/icons/ig.svg')):
            require('../../assets/images/icons/ig.svg'),"alt":""}})]),_c('a',{attrs:{"href":"https://www.facebook.com/profile.php?id=100005973528422","target":"_blank"}},[_c('img',{attrs:{"src":_vm.production ? 
            _vm.path_prefix.slice(0, -1).concat(require('../../assets/images/icons/fb.svg')):
            require('../../assets/images/icons/fb.svg'),"alt":""}})]),_c('a',{attrs:{"href":"https://rs.linkedin.com/in/ljubica-jovanovic-b8a445124?trk=people-guest_people_search-card&original_referer=https%3A%2F%2Fwww.linkedin.com%2F","target":"_blank"}},[_c('img',{attrs:{"src":_vm.production ? 
            _vm.path_prefix.slice(0, -1).concat(require('../../assets/images/icons/in.svg')):
            require('../../assets/images/icons/in.svg'),"alt":""}})]),_c('a',{attrs:{"href":"https://www.youtube.com/@mentaltennis/featured","target":"_blank"}},[_c('img',{attrs:{"src":_vm.production ? 
            _vm.path_prefix.slice(0, -1).concat(require('../../assets/images/icons/yt.svg')):
            require('../../assets/images/icons/yt.svg'),"alt":""}})])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }