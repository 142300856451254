<template>
  <div class="footer-wrap">
    <div class="footer">
      <div class="top">
        <div class="top-grp logo-grp">
          <img  
          :src="
              production ? 
              path_prefix.slice(0, -1).concat(require('../assets/images/icons/logo-no-text.svg')):
              require('../assets/images/icons/logo-no-text.svg')"
          alt="" />
          <a to="/">
            <span class="logo-text">Ljubica Jovanović</span>
          </a>
        </div>
        <a href="tel:+381 63 8 654 597">
          <div class="top-grp">
            <img
              :src="
              production ? 
              path_prefix.slice(0, -1).concat(require('../assets/images/icons/phone.svg')):
              require('../assets/images/icons/phone.svg')"
              alt=""
              class="icon"
            />
            <span>+381 63 8654 597</span>
          </div>
        </a>
        <a href="mailto:ljubica.jovanovic.coach@gmail.com">
          <div class="top-grp">
            <img 
            :src="
              production ? 
              path_prefix.slice(0, -1).concat(require('../assets/images/icons/mail.svg')):
              require('../assets/images/icons/mail.svg')"
            alt="" class="icon" />
            <span>ljubica.jovanovic.coach@gmail.com</span>
          </div></a
        >
        <div class="top-grp">
          <a
            href="https://www.instagram.com/ljubica_jovanovic_coach/"
            target="_blank"
            ><img
              class="icon-soc"
              :src="
                production ? 
                path_prefix.slice(0, -1).concat(require('../assets/images/icons/ig.svg')):
                require('../assets/images/icons/ig.svg')"
              alt=""
            />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100005973528422"
            target="_blank"
            ><img
              class="icon-soc"
              :src="
              production ? 
              path_prefix.slice(0, -1).concat(require('../assets/images/icons/fb.svg')):
              require('../assets/images/icons/fb.svg')"

              alt=""
            />
          </a>
          <a
            href="https://rs.linkedin.com/in/ljubica-jovanovic-b8a445124?trk=people-guest_people_search-card&original_referer=https%3A%2F%2Fwww.linkedin.com%2F"
            target="_blank"
          >
            <img
              class="icon-soc"
              :src="
              production ? 
              path_prefix.slice(0, -1).concat(require('../assets/images/icons/in.svg')):
              require('../assets/images/icons/in.svg')"
              alt=""
            />
          </a>
          <a
            href="https://www.youtube.com/@mentaltennis/featured"
            target="_blank"
            ><img
              class="icon-soc"
              :src="
              production ? 
              path_prefix.slice(0, -1).concat(require('../assets/images/icons/yt.svg')):
              require('../assets/images/icons/yt.svg')"
              alt=""
            />
          </a>
        </div>
      </div>
      <div class="bot">
        <span>Created by ENON Solutions</span>
        <img
          class="bot-logo"
          :src="
              production ? 
              path_prefix.slice(0, -1).concat(require('../assets/images/icons/enon-logo.svg')):
              require('../assets/images/icons/enon-logo.svg')"
          alt=""
        />
        <span id="footer-rights"
          >© 2023 Ljubica Jovanović. All rights reserved.
        </span>
        <img
          class="bot-logo"
          :src="
              production ? 
              path_prefix.slice(0, -1).concat(require('../assets/images/icons/mars-logo.svg')):
              require('../assets/images/icons/mars-logo.svg')"
          alt=""
        />
        <span>Powered by MARS</span>
      </div>
      <div class="bot-mobile">
        <div class="enon">
          <span>Created by ENON Solutions.</span>
          <img
            class="enonlogo"
            :src="
              production ? 
              path_prefix.slice(0, -1).concat(require('../assets/images/icons/enon-logo.svg')):
              require('../assets/images/icons/enon-logo.svg')"
            alt=""
          />
        </div>
        <p id="rights">© 2023 Ljubica Jovanović. All rights reserved.</p>
        <div class="mars">
          <span> Powered by MARS. </span>
          <img
            class="marslogo"
            :src="
              production ? 
              path_prefix.slice(0, -1).concat(require('../assets/images/icons/mars-logo.svg')):
              require('../assets/images/icons/mars-logo.svg')"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default{
  data(){
    return{
      production: false,
      path_prefix: null,
    }
  },
  mounted() {
        this.setSid= localStorage.getItem('sid')
        this.production = process.env.NODE_ENV === 'production'
        this.path_prefix = window.__blog_path_prefix

  },
}
</script>
<style scoped lang="scss">
a{
  text-decoration: none;
}
a:link {
  color: var(--text-color);
}
a:visited  {
  color: var(--text-color);
}
a:hover  {
  color: var(--text-color);
}
.footer-wrap {
  position: relative;
  z-index: 20;
  padding: 60px 30px 0 30px;
  background-color: var(--header-background-color);

  .footer {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;

    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      a {
        display: grid;
        place-items: center;
      }

      .logo-grp {
        img {
          width: 55px;
        }

        span {
          margin-left: 15px;
        }
      }

      .top-grp {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;

        .icon {
          margin-right: 15px;
        }

        .icon-soc {
          margin-left: 30px;
        }
      }
    }
  }

  .bot {
    border-top: 1px solid #ffffff2b;
    display: flex;
    justify-content: space-between;
    padding: 10px 0 30px;
    color: #ffffff80;

    .bot-logo {
      height: 24px;
    }
  }

  .bot-mobile {
    border-top: 1px solid #ffffff2b;
    color: #ffffff80;
    max-width: 400px;
    padding: 20px 0;
    display: none;
    margin: 0 auto;
    flex-direction: column;
    justify-content: space-between;

    #rights {
      white-space: nowrap;
      margin: 10px 0;
    }

    .enon,
    .mars {
      display: flex;
      justify-content: space-between;

      /* stylelint-disable-next-line no-descending-specificity */
      .marslogo {
        height: 18px;
        top: 3px;
      }

      .enonlogo {
        height: 30px;
      }
    }
  }
}

@media only screen and (width <= 1000px) {
  .footer-wrap {
    .footer {
      .top {
        flex-direction: column;

        .logo-grp {
          height: 100px;

          .logo-text {
            display: none;
            height: 80px;
          }

          img {
            height: 100%;
            width: 100%;
          }

          .desktop {
            display: none;
          }
        }

        .top-grp {
          justify-content: center;
          margin-bottom: 20px;
          align-items: center;

          .icon {
            margin-right: 10px;
          }

          .icon-soc {
            margin-left: 20px;
          }
        }
      }

      .bot {
        display: none;
      }

      .bot-mobile {
        display: flex;
      }
    }
  }
}
</style>
