<template lang="html">
  <div class="create-blog-wrapper">
    <div class="input-wrapper">
      <span class="title">{{$t('admin.create.title')}}: ({{blog.title?blog.title.length:0}} {{$t('admin.create.enteredChar')}} - 255 {{$t('admin.create.maxChar')}})</span>
      <input type="text" maxlength="255" v-model="blog.title">
    </div>
    <div class="input-wrapper">
      <span class="title"> {{$t('admin.create.description')}}: ({{blog.description ? blog.description.length:0}} {{$t('admin.create.enteredChar')}} - 500 {{$t('admin.create.maxChar')}})</span>
      <input type="text" maxlength="500" v-model="blog.description">
    </div>
    <div class="input-wrapper">
      <span class="title">{{$t('admin.create.date')}}:</span>   <!--   (yyyy-mm-dd) -->
      <input type="date" v-model="blog.date" format="dd-mm-yyyy">
    </div>
    <div v-if="false" class="input-wrapper">
      <span class="title">{{$t('admin.create.minutes')}}:</span>
      <input type="number" max="255" v-model="blog.readTime">
    </div>
    <div class="input-wrapper">
      <span class="title"> {{$t('admin.create.author')}}: ({{blog.author ? blog.author.length:0}} {{$t('admin.create.enteredChar')}} - 255 {{$t('admin.create.maxChar')}})</span>
      <input type="text" maxlength="255" v-model="blog.author">
    </div>
    <div class="input-wrapper">
      <span class="title"> {{$t('admin.create.link')}}: ({{blog.link ? blog.link.length:0}} {{$t('admin.create.enteredChar')}} - 255 {{$t('admin.create.maxChar')}})</span>
      <input type="text" maxlength="255" v-model="blog.link">
    </div>
<!-- thumb image -->


    <addImgBlog 
      :mainTitle="$t('admin.create.imgSmall')"
      :inputTitle="$t('admin.create.imgSmall')+' (1.91:1 aspect ratio)'"
      @delete="removeImageThumb()"
      @setimg="setThumb"
      :clear="clearInput"
    />

    <div v-if="false" class="input-wrapper">
      <span class="title"> {{$t('admin.create.thumbAlt')}}: ({{blog.thumb_alt ? blog.thumb_alt.length:0}} {{$t('admin.create.enteredChar')}} - 255 {{$t('admin.create.maxChar')}})</span>
      <input type="text" maxlength="255" v-model="blog.thumb_alt">
    </div>
    <div v-if="false" class="input-wrapper">
      <span class="title"> {{$t('admin.create.thumbName')}}: ({{blog.thumb_name ? blog.thumb_name.length:0}} {{$t('admin.create.enteredChar')}} - 255 {{$t('admin.create.maxChar')}})</span>
      <input type="text" maxlength="255" v-model="blog.thumb_name">
    </div>

<!-- main image -->


    <addImgBlog 
        :mainTitle="$t('admin.create.imgMain')"
        :inputTitle="$t('admin.create.imgMain')+': (0 - 1.5mb)'"
        @delete="removeImage()"
        @setimg="setImage"
        :clear="clearInput"
    />
    <div v-if="false" class="input-wrapper">
      <span class="title"> {{$t('admin.create.imgAlt')}}: ({{blog.img_alt ? blog.img_alt.length:0}} {{$t('admin.create.enteredChar')}} - 255 {{$t('admin.create.maxChar')}})</span>
      <input type="text" maxlength="255" v-model="blog.img_alt">
    </div>
    <div v-if="false" class="input-wrapper">
      <span class="title"> {{$t('admin.create.imgName')}}: ({{blog.img_name ? blog.img_name.length:0}} {{$t('admin.create.enteredChar')}} - 255 {{$t('admin.create.maxChar')}})</span>
      <input type="text" maxlength="255" v-model="blog.img_name">
    </div>

<!--  -->
    <!-- <div class="input-wrapper">
      <span class="title"> {{$t('admin.create.maxPoints')}}:</span>
      <input type="number" maxlength="255" v-model="blog.max_points">
    </div> -->

    <div class="input-wrapper">
          <span class="title">{{$t('admin.create.categories')}}: </span>
          <div class="setCheck">
            <div class="itemCheck" v-for="(caat, index) in categoriseApi" :key="index">
              <input v-if="caat.cat_deleted !=1" type="checkbox"  name="selectCate" :id="caat.cat_id" :value="caat.cat_id" v-model="checkedList" >
              <label v-if="caat.cat_deleted !=1" class='caat' :for="caat.cat_id">{{caat.cat_name}}</label>
            </div>
          </div>
        </div>

    <div class="localePickerAdmin">
       <LocalePicker @setlocal="setLocal"/>
    </div> 
    <dropdown  
      :defaultText="$t('main.status')"
      :selected="blog.status"
      :list="getBlogStatuses"
      @changeOption="blog.status = $event"
    />

<!--  -->

    <div class="input-wrapper">
      <span class="title">{{$t('admin.create.content')}}: 
          {{readSize(blog.content)}} 
          {{$t('admin.create.entered')}}, 
          (20MB max)
        </span>
      <vue-editor v-model="blog.content"></vue-editor>
      <div class="preview" id="previewId">
        <div class="title">{{$t('admin.create.preview')}}:</div>
        <div v-html="getPreview" class="content">
        </div>
      </div>
    </div>
    <div class="btn-wrapper">
      <div @click="resetPage" class="buttonCancel">
        {{$t('admin.create.reset')}}
      </div>
      <div @click="create" class="buttonSend">
        {{$t('admin.create.create')}}
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '@/api'
import { VueEditor } from "vue2-editor";
// import axios from 'axios'
import LocalePicker from '../LocalePickerForm.vue'
import dropdown from '../inputcomponents/drop.vue'
import { mapGetters } from 'vuex';
import addImgBlog from './addImgBlog.vue'
import helperB from "@/js/helperByteCalc.js"
 
export default {
  name: 'createBlog',
  components: {
    VueEditor,
    LocalePicker,
    dropdown,
    addImgBlog
  },
  data () {
    return {
      blog: {
        title: null,
        description: null,
        date: null,
        readTime: 5,
        author: "Ljubica Jovanović",
        content: "<h1>Some initial content</h1>",
        link: null,
        thumb: null,
        thumb_name: null,
        thumb_alt: null,
        image: null,
        img_alt: null,
        img_name: null,
        type: 0,
        max_points: null,
        locale: null,
        status: null,

      },
      required: {
        title: null,
        description: null,
        date: null,
        readTime: null,
        author: null,
        content: "<h1>Some initial content</h1>",
        link: null,
        locale: null,
        status: null
      },
       categoriseApi: null,
       checkedList:[],
       clearInput: false
    }
  },
  mounted(){
    this.setCategory()
  },
  activated(){
    this.setCategory()
  },
  methods: {
    setImage (e) {
      console.log(e)
      this.blog.image = e
    },
    setThumb(e){
      this.blog.thumb = e
    },
    create () {
      if (!this.checkAllParams()) {
        return
      } else {
        let params = new FormData()
        for (var variable in this.blog) {
          // console.log(this.blog[variable]);
          if (this.blog[variable] !== null) {
            if(variable === 'status') {
              params.append(variable, this.blog[variable].id)
            }else {
              params.append(variable, this.blog[variable])
            }
          }
        }
        // params.append('locale', this.$i18n.locale)
        params.append('categories', JSON.stringify(this.checkedList))
        api.createBlog(params).then(() => {
          alert('blog created successfuly')
          this.resetPage()
        }).catch(e => {
        //  console.log();
          alert('error creating blog: ' + e.response.data.message)
        })
      }
    },
    validate(evt) {
      // for date
      var theEvent = evt || window.event;

      // Handle paste
      if (theEvent.type === 'paste') {
          key = event.clipboardData.getData('text/plain');
      } else {
      // Handle key press
          var key = theEvent.keyCode || theEvent.which;
          key = String.fromCharCode(key);
      }
      var regex = /[0-9-]|\./;
      if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
      }
    },
    checkAllParams () {
      for (var variable in this.required) {
        if (this.blog[variable] === null || this.blog[variable] === '') {
          if(variable === 'locale') {
            this.blog[variable] = this.$i18n.locale
          } else {
            alert(this.getName(variable) + ' is missing!')
            return false
          }
        }
      }
      return true
    },
    setLocal(local){
      this.blog.locale = local
    },
    getName (variable) {
      if (variable === 'img_alt') {
        return 'image alt'
      }
      if (variable === 'img_name') {
        return 'image name'
      }
      if (variable === 'readTime') {
        return 'minutes to read'
      }
       if (variable === 'thumb') {
        return 'thumb image'
      }
      if (variable === 'thumb_name') {
        return 'thumb name'
      }
      if (variable === 'thumb_alt') {
        return 'thumb alt'
      }
      return variable
    },
    resetPage () {
      this.blog = {
        title: null,
        description: null,
        date: null,
        readTime: null,
        author: "Ljubica Jovanović",
        content: "<h1>Some initial content</h1>",
        link: null,
        image: null,
        img_alt: null,
        img_name: null,
        type: 0,
        status: null
      }
      this.checkedList=[]
      this.clearInput=!this.clearInput
    },
     async setCategory(){
     await api.getCategories().then(res => {
        this.categoriseApi = res.data.data
      }).catch(() => {
      })
    },
    removeImage () {
      this.blog.image = null
    },
    removeImageThumb () {
      this.blog.thumb = null
    },
    readSize(content){
      return  helperB.byteToMB( helperB.createBlob(content))
    }
  },
  watch: {
  },
  computed: {
    ...mapGetters(['getBlogStatuses']),
    getPreview () {
      return this.blog.content.replaceAll('&lt;', '<').replaceAll('&gt;','>')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/adminBlogForm.scss';

</style>
<style lang="scss">
.localePickerAdmin{
  background: var(--main-background-color);
  width: 65px;
  height: 150px;
  color: #5A7E87;
  .picker-wrapper {
    height: fit-content;
    display: flex;
    justify-items: flex-start;
    align-items: flex-start;
    color: #5A7E87;
  .picker-title{
    color: #5A7E87;
  }
  }
}
</style>
