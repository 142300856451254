<template>
    <transition name="slide-fade">
        <div class="comment">
            <div v-if="comment.com_id_parent" class="wrapp">
                <div v-if="comment.parent_com_approved
                    || comment.parent_com_deleted" 
                    class="wrappStatus"
                    :style="{background: isDeleted( comment.parent_com_approved , comment.parent_com_deleted) ? '#FF4B49':''}"
                    >
                    <div class="name" v-html="
                        setText(
                            comment.parent_com_approved,
                            comment.parent_com_deleted,
                            comment.parent_approved_by,
                            comment.parent_deleted_by
                        )
                    ">
                    </div>
                    <div class="blgName">
                        {{ comment.blg_title }}
                    </div>
                    <div class="date">{{ checkTwoDates(comment.parent_com_approved,
                        comment.parent_com_deleted) }}</div>
                </div>
                <div class="mainComment parent">
                    <div class="wrappMaindata">
                        <div class="name">{{ comment.parent_usr_name }}</div>
                        <div class="date" v-if="comment.parent_created">{{ formatDate(comment.parent_created) }}
                        </div>
                    </div>
                    <div class="wrappComm">
                        {{ comment.parent_com_content }}
                        <div class="wrappIcon">
                            <confirmIcon 
                             v-if="
                               ( comment.parent_com_approved === null
                                && comment.parent_com_deleted === null )
                                || isDeleted( comment.parent_com_approved , comment.parent_com_deleted)
                            "
                                type="confirm"
                                @set="approve(comment.com_id_parent, comment.parent_com_content)" />
                            <confirmIcon 
                                v-if="
                                    (comment.parent_com_deleted === null 
                                    && comment.parent_com_approved === null)
                                    ||  !isDeleted( comment.parent_com_approved , comment.parent_com_deleted)
                                "
                                type="delete" 
                                @set="delete1(comment.com_id_parent, comment.parent_com_content)"
                            />
                            <!-- <confirmIcon v-else-if="comment.parent_com_deleted" type="undo" @set="undo(comment.com_id_parent, comment.parent_com_content)" /> -->
                        </div>
                    </div>
                </div>
                <div class="more">
                    <div class="line"></div>
                    <div class="wrappSubComm">
                        <div class="subComment">
                            <div class="line"></div>
                            <div class="wrappData">
                                <div v-if="comment.com_approved
                                    || comment.com_deleted" 
                                    class="wrappStatus"
                                    :style="{background: isDeleted( comment.com_approved , comment.com_deleted) ? '#FF4B49':''}"
                                    >
                                    <div class="name" v-html="
                                        setText(
                                            comment.com_approved,
                                            comment.com_deleted,
                                            comment.approved_by,
                                            comment.deleted_by
                                        )
                                    "></div>
                                    <div class="blgName">
                                        {{ comment.blg_title }}
                                    </div>
                                    <div class="date">{{ checkTwoDates(comment.com_approved,
                                     comment.com_deleted) }}</div>
                                </div>
                                <div class="wrappData">
                                    <div class="wrappMaindata">
                                        <div class="name">{{ comment.usr_name }}</div>
                                        <div class="date" v-if="comment.com_created">{{ formatDate(comment.com_created) }}
                                        </div>
                                    </div>
                                    <div class="wrappComm">
                                        {{ comment.com_content }}
                                        <div class="wrappIcon">
                                            <confirmIcon 
                                                v-if="
                                                ( comment.com_approved === null
                                                && comment.com_deleted === null )
                                                || isDeleted( comment.com_approved , comment.com_deleted)
                                                " 
                                                type="confirm"
                                                @set="approve(comment.com_id, comment.com_content)" 
                                            />
                                            <confirmIcon 
                                                v-if="
                                                    (comment.com_deleted === null 
                                                    && comment.com_approved === null)
                                                    || !isDeleted( comment.com_approved , comment.com_deleted)"
                                                type="delete"
                                                @set="delete1(comment.com_id, comment.com_content)"
                                            />
                                            <!-- <confirmIcon v-else-if="comment.com_deleted" type="undo" -->
                                                <!-- @set="undo(comment.com_id, comment.com_content)" /> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="more">
                    <div class="option" @click="leaveComment = !leaveComment">{{ $t('comment.reply') }}</div>
                </div>
                <div class="more more2">
                    <transition name="bounce">
                        <wrappLeaveComment 
                        :com_id="comment.com_id_parent"  
                        v-if="leaveComment" 
                        @close="leaveComment = !leaveComment"
                        />
                    </transition>
                </div>
            </div>
            <div v-else class="wrapp">
                <div v-if="comment.com_approved
                    || comment.com_deleted" 
                    class="wrappStatus"
                    :style="{background: isDeleted( comment.com_approved , comment.com_deleted) ? '#FF4B49':''}">
                    <div class="name" v-html="
                        setText(
                            comment.com_approved,
                            comment.com_deleted,
                            comment.approved_by,
                            comment.deleted_by
                        )
                    "></div>
                    <div class="blgName">
                        {{ comment.blg_title }}
                    </div>
                    <div class="date">{{ checkTwoDates(comment.com_approved,
                        comment.com_deleted) }}</div>
                </div>
                <div class="mainComment">
                    <div class="wrappMaindata">
                        <div class="name">{{ comment.usr_name }}</div>
                        <div class="date">{{ formatDate(comment.com_created) }}</div>
                    </div>
                    <div class="wrappComm">
                        {{ comment.com_content }}
                        <div class="wrappIcon">
                            <confirmIcon 
                                v-if="
                                (comment.com_approved === null 
                                && comment.com_deleted === null)
                                || isDeleted( comment.com_approved , comment.com_deleted)
                                " 
                                type="confirm"
                                @set="approve(comment.com_id, comment.com_content)" />
                            <confirmIcon
                                v-if="
                                (comment.com_deleted === null
                                && comment.com_approved === null)
                                || !isDeleted( comment.com_approved , comment.com_deleted)
                                " 
                                 type="delete" 
                                 @set="delete1(comment.com_id, comment.com_content)" />
                            <!-- <confirmIcon v-else-if="comment.com_deleted" type="undo" @set="undo(comment.com_id, comment.com_content)" /> -->
                        </div>
                    </div>
                </div>


                <div class="more">
                    <div class="option" @click="leaveComment = !leaveComment">{{ $t('comment.reply') }}</div>
                </div>
                <div class="more more2">
                    <transition name="bounce">
                        <wrappLeaveComment :com_id="comment.com_id" :blog="blog" v-if="leaveComment" />
                    </transition>
                </div>

            </div>


        </div>
    </transition>
</template>
  
<script>
import wrappLeaveComment from './wrappLeaveComment.vue'
import confirmIcon from '../confirmIcon.vue'
import { api } from '@/api'
export default {
    name: 'mainComment',
    props: ['comment'],
    components: {
        wrappLeaveComment,
        confirmIcon
    },
    data() {
        return {
            leaveComment: false,
            subCommentsRead: true,
        }
    },
    mounted() {
    },
    methods: {
        formatDate(d) {
            if (!d) {
                return null
            }
            let format = new Date(d).toISOString().split('T')[0]
            return format.split("-").reverse().join(".");
        },
        checkTwoDates(approved, deleted) {
            if (approved === null && deleted !== null) {
                return this.formatDate(deleted)
            } else if (approved !== null && deleted === null) {
                return this.formatDate(approved)
            } else if (approved !== null && deleted !== null) {
                let appr = null
                let del = null
                try {
                    appr = new Date(approved).getTime()
                    del = new Date(deleted).getTime()
                } catch (error) {
                    console.error(error);
                    // Expected output: ReferenceError: nonExistentFunction is not defined
                }
                // console.log('two dates'+(appr > del))
                if (appr > del) {
                    return this.formatDate(approved)
                } else {
                    return this.formatDate(deleted)
                }
            }

        },
        isDeleted(approved, deleted) {
            if (approved === null && deleted !== null) {
                return true
            } else if (approved !== null && deleted === null) {
                return false
            } else if (approved !== null && deleted !== null) {
                let appr = null
                let del = null
                try {
                    appr = new Date(approved).getTime()
                    del = new Date(deleted).getTime()
                } catch (error) {
                    console.error(error);
                }
                if (appr > del) {
                    return false
                } else {
                    return true
                }
            }

        },
        setText(approved, deleted, usr_appr, usr_del) {
            if (approved === null && deleted !== null) {
                return `Deleted by <span style="font-weight: 700">${usr_del}</span>`
            } else if (approved !== null && deleted === null) {
                return `Approved by  <span style="font-weight: 700">${usr_appr}</span>`
            } else if (approved !== null && deleted !== null) {
                let appr = null
                let del = null
                try {
                    appr = new Date(approved).getTime()
                    del = new Date(deleted).getTime()
   
                } catch (error) {
                    console.error(error);
                    // Expected output: ReferenceError: nonExistentFunction is not defined
                }
             
                if (appr > del) {
                    return `Approved by  <span style="font-weight: 700">${usr_appr}</span>`
                } else {
                    return `Deleted by <span style="font-weight: 700">${usr_del}</span>`
                }


            }
        },
        delete1(e, text) {
            text = text.length > 150 ? text.substring(0, 150) + '...' : text
           
            let params = {
                    com_id: e,
                    is_restore: false
                }
            let confirm = window.confirm(`Are you sure you want delete \n  "${text}"\n comment?`)
            if(!confirm) {
                return
            }
            api.deleteComments(params).then(res => {
                console.log(res)
                this.$emit('refresh')
            }).catch(e => {
                console.log(e)
                alert('Something went wrong, please try again.')
            })
        },
        undo(e, text) {
            text = text.length > 150 ? text.substring(0, 150) + '...' : text
            
            let params = {
                    com_id: e,
                    is_restore: true
                }
            let confirm = window.confirm(`Are you sure you want restore \n  "${text}"\n comment?`)
            if(!confirm) {
                return
            }
            api.deleteComments(params).then(res => {
                
                console.log(res)
                this.$emit('refresh')
            }).catch(e => {
                console.log(e)
                alert('Something went wrong, please try again.')

            })
        },
        approve(e, text) {
            text = text.length > 150 ? text.substring(0, 150) + '...' : text
            // console.log(e)
            let params = {
                com_id : e
            }
            let confirm = window.confirm(`Are you sure you want approve \n  "${text}"\n comment?`)
            if(!confirm) {
                return
            }
            api.approveComment(params).then(res=>{
                console.log(res)
                this.$emit('refresh')
            }).catch(e => {
                console.log(e)
                alert('Something went wrong, please try again.')

            })

            
        },
    }

}
</script>
  
<style lang='scss' scoped>
.wrapp {
    margin-bottom: 25px;
    position: relative;
    z-index: 10;
    // border-bottom: 1px solid var(--border-color) ;
}



.mainComment {
    //  max-width:100%;
    //  width: fit-content;
    width: 100%;
    min-height: 80px;
    padding: 10px;
    box-sizing: border-box;
    background-color: var(--comments-background-color);
    // background: #ECECEC;
    border-radius: 25px;
    padding: 18px 17px;
    transition-duration: height .3s;
    filter: brightness(1);

    .wrappMaindata {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3px;
        font-weight: bold;

        .name {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 17px;
            color: var(--title-color);
        }

        .date {
            margin-left: 30px;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            /* identical to box height, or 167% */

            letter-spacing: 0.04em;
            color: var(--text-soft-color);

        }
    }



}

.more {
    margin-left: 13px;
    border-left: 1px solid var(--comments-background-color);
    background-color: var(--main-background-color);


    .option {
        padding-left: 10px;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        color: var(--text-lighter);
        margin-bottom: 5px;
        cursor: pointer;
    }

    .wrappSubComm {
        display: flex;
        flex-direction: column;
        transition: height .3s;

    }

    .wrappSubComm {
        padding-top: 25px;


        .subComment {
            border-radius: 5px;
            box-sizing: border-box;
            width: 100%;
            padding: 10px;
            padding-left: 0;
            padding-right: 17px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background-color: var(--main-background-color);


            .line {
                box-sizing: border-box;
                width: 50px;
                height: 0;
                border-top: 1px solid var(--comments-background-color);
            }

            .wrappData {
                width: calc(100% - 50px);
                // padding-left: 22px;
                background-color: var(--main-background-color);
                position: relative;
                z-index: 10;
                filter: brightness(1);

                .wrappData {
                    width: calc(100%);
                    box-sizing: border-box;
                    padding: 14px 18px 0 18px;
                    background-color: var(--main-background-color);
                    position: relative;
                    z-index: 10;
                    filter: brightness(1);
                    border-radius: 25px;
                }
            }

            .wrappMaindata {
                display: flex;
                justify-content: space-between;
                // margin-bottom: 10px;
                font-weight: bold;

                .name {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 17px;
                    color: var(--text-color);
                }

                .date {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    /* identical to box height, or 167% */

                    letter-spacing: 0.04em;
                    color: var(--text-soft-color);

                }
            }
        }
    }
}

.more2 {
    border-left: 1px solid transparent;
    background-color: var(--main-background-color);
    // height: 237px ;
    // transition: height .3s;
    overflow: hidden;
    // 50px->line, 22px->padding from line to content, 13px->margin of more 
    margin-left: calc(50px + 22px + 13px);
}

.wrappStatus {
    display: flex;
    justify-content: space-between;
    // margin-bottom: 10px;
    // font-weight: bold;

    // background-color: var(--btn-color);
    background-color: #36714a;
    filter: brightness(2.8);
    position: absolute;
    width: 100%;
    top: -20px;
    left: 0px;
    box-sizing: border-box;
    padding: 3px 27px;
    padding-bottom: 25px;
    border-radius: 25px 25px 0 0;
    z-index: 0;
   

    .name {
        font-style: normal;
        font-size: 14px;
        line-height: 17px;
        color: var(--text-color);
        color: var(--main-background-color)!important;

        span {
            font-weight: 700;
        }
    }
    .blgName{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 50%; 
        justify-self: end;
        font-weight: 900;
        font-size: 14px;
        line-height: 17px;
        color: var(--text-color);
        color: var(--main-background-color)!important;
    }

    .date {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        /* identical to box height, or 167% */

        letter-spacing: 0.04em;
        color: var(--text-color);
        color: var(--main-background-color)!important;

    }

}

.parent {
    filter: brightness(.85);
}

.wrappComm {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.wrappIcon {
    display: flex;
    align-items: center;
}
</style>
<style>
.bounce-enter-active {
    animation: bounce-in .5s;
}

.bounce-leave-active {
    animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    /* 50% {
      transform: scale(0.5);
    } */
    100% {
        transform: scale(1);
    }
}
</style>