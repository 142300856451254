<template>
<div class="ShareIcon">
        <ShareNetwork
             v-for="(obj , index) in list"
            :key="index"
            :url=" obj.network==='email'? 'Check this at: ' + url1 + currentUrl : url1 + currentUrl"
            :title='titleP ? titleP : title'
            :description="descriptionP ? '\n '+ descriptionP +' \n \n'+ url + ' \n \n' : '\n '+description +' \n \n'+ url + ' \n \n'"
            :quote="url"
            :hashtags="hashTags ? hashTags : hashtags"
            :network="obj.network"
            class="spanImg"    
        ><a class="aLink" href="#" >
           <img  :src="
            production ?
            path_prefix.slice(0, -1).concat(obj.img): 
            obj.img" alt="/">
          </a>
        </ShareNetwork>   
        <div class="spanImg lastImg" @click="showModalEmit">
            <a class="aDot" href="#"  >
                <img 
                :src="
                production ?
                path_prefix.slice(0, -1).concat(require('../../assets/images/dot.svg')) :
                require('../../assets/images/dot.svg')" 
                style="width:14px" 
                alt="/">
            </a>
        </div>
        <modalShare 
             v-if="showmodal"
             @closeshowmodal="showModalEmit" 
             :author="author" 
             :descriptionP="descriptionP"
             :titleP="titleP"
             :hashTags="hashTags"
          />
      </div>
  
</template>

<script>
import facebookImg from '@/assets/images/fb.svg'
import linkedinImg from '@/assets/images/linkedin.svg'
// import twitterImg from '@/assets/images/tweetgray.svg'
// import instagram from '@/assets/images/instagram.svg'
import modalShare from './modalShare.vue'
import { baseURL } from '@/api'
export default {
    props: ['titleP', 'descriptionP', 'author', 'hashTags'],
    components: {
        modalShare
    },
    data () {
        return {
                title: 'Blog post',
                description: "",
                url: "https://link.co.nz/", // https://link.co.nz/ - slash in the end
                url1: "https://link.co.nz", // https://link.co.nz
                hashtags: "blog",// "cars,carsnz,carblogs"
             list: [
                {network: 'facebook', img: facebookImg, txt: 'Facebook'},
                {network: 'linkedIn', img: linkedinImg, txt: 'linkedin'},
                // {network: 'twitter', img: twitterImg, txt: 'Twitter'},
                // {network: 'Instagram', img: instagram, txt: 'instagram'}
            ],
            showmodal:false,
            production: false,
            path_prefix: null
        }
    },
    created (){
       this.currentUrl = this.$route.path
       this.setUrl()

       this.production = process.env.NODE_ENV === 'production'
       this.path_prefix = window.__blog_path_prefix

      },
      methods: {
        setShareUrl( slash) {
            // if(slash)
            //     return this.baseURL.slice(0,this.baseURL.length-5)
            // else 
            //      return this.baseURL.slice(0,this.baseURL.length-4)
            if(slash)
                return this.baseURL //.concat('/')
            else 
                 return this.baseURL
        },
        setUrl() {
            //  this.url= 'https:'+this.setShareUrl(false) // "https://test.co.nz/"
            //  this.url1= 'https:'+this.setShareUrl(true) //"https://test.co.nz"
            this.url= this.setShareUrl(false) // "https://test.co.nz/"
            this.url1= this.setShareUrl(true) //"https://test.co.nz"

        },
        showModalEmit() {
            this.showmodal= !this.showmodal
             this.$emit('showmodal', this.showmodal)
        }
    },
       computed: {
        baseURL () {
            if (process.env.NODE_ENV !== 'production') {
            //    return 'http:' + baseURL.slice(0,baseURL.length-1) //'https://028x122.mars-t.mars-hosting.com'
            return 'http:' + baseURL.replace('/api/', '')   
            
            }
            return window.location.protocol + '//' + window.location.host //+ window.__blog_path_prefix.slice(0, -1)
            }
        }

}
</script>

<style lang="scss" scoped>
    .ShareIcon{
        display: flex;
        height: fit-content;
        .spanImg{
            justify-content: center;
            align-items: center;
            margin-right: 20px;
            .aLink {
                img {
                    height: 20px;
                }
            }
        }
        .lastImg{
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .aDot {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 3px;
                img {
                    display:block 
                }
            }
        }
    }
</style>