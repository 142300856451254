<template lang="html">
  <div class="edit-blogs-wrapper create-blog-wrapper">
    <div v-if="!blog" class="no-selection">
      <search @search="search" :reset="resetSearch"/>

      <blogItem :class="{ last : index === blogs.length - 1 }" @blogClick="selectBlog(blog)" v-for="(blog, index) in blogs" :key="index" :deleteOnClick="true" :blogData="blog" />
      <div class="show-more-wrapper">
        <div v-if="more" @click="getBlogsN()" class="show-more">
          {{$t('admin.create.showMore')}}
        </div>
      </div>
    </div>
<!-- edit blog start -->
    <div v-else class="selected">
      <div @click="returnOnList" class="return">
        <div class="circle">
          <img 
          :src="
          production ?
          path_prefix.slice(0,-1).concat(require('../../assets/images/icons/left_white_arr.svg')):
          require('../../assets/images/icons/left_white_arr.svg')" alt="">
        </div>
        
        {{ $t('admin.create.return') }}
      </div>
<!-- create duplicate -->
      <div class="input-wrapper create" v-if="$i18n.availableLocales.length > removeLocale.length">
        <span class="title" @click="createDuplicate = !createDuplicate">{{ $t('admin.create.createDuplicateTXT') }}, <span class="colorBTN"> {{ $t('admin.create.clickHere') }}</span>.</span>
        <div v-if="createDuplicate" class="flex duplicate">
          <LocalePicker @setlocal="setLocal" :removeString="removeLocale"/>
          <div @click="createNew" class="buttonSend" style="width:fit-content" >
            {{ $t('admin.create.createDuplicate') }}
          </div>
        </div>
      </div>
<!--end create duplicate -->

      <div class="input-wrapper">
        <h1>{{ locationChanges }}</h1>
      </div>

      <div class="input-wrapper">
        <span class="title">{{$t('admin.create.title')}}: (1 - 255) {{ blog.title.length  }} {{$t('admin.create.enteredChar')}}</span>
        <input type="text" maxlength="255" v-model="blog.title">
      </div>
      <div class="input-wrapper">
        <span class="title">{{$t('admin.create.description')}}: (1 - 500) {{ blog.description.length  }} {{$t('admin.create.enteredChar')}}</span>
        <input type="text" maxlength="500" v-model="blog.description">
      </div>
      <div class="input-wrapper">
        <span class="title">{{$t('admin.create.date')}}: </span>
        <input type="date" v-model="blog.date">
      </div>
      <div v-if="false" class="input-wrapper">
        <span class="title">{{$t('admin.create.minutes')}}:</span>
        <input type="number" max="255" v-model="blog.readTime">
      </div>
      <div class="input-wrapper">
        <span class="title">{{$t('admin.create.author')}}: (1 - 255) {{ blog.author.length  }} {{$t('admin.create.enteredChar')}} </span>
        <input type="text" maxlength="255" v-model="blog.author">
      </div>
      <div class="input-wrapper">
        <span class="title">{{$t('admin.create.link')}}: (1 - 255) {{ blog.link.length  }} {{$t('admin.create.enteredChar')}}</span>
        <input type="text" maxlength="255" v-model="blog.link">
      </div>

      <addImgBlog 
        :mainTitle="$t('admin.create.imgSmall')"
        :inputTitle="$t('admin.create.imgSmall')+' (1.91:1 aspect ratio)'"
        :oldImage="blog.thumb"
        @delete="removeImageThumb()"
        @setimg="setThumb"
        :clear="clearInput"
      />
      <div v-if="false" class="input-wrapper">
        <span class="title">Thumb alt: (1 - 255 characters) {{ blog.thumb_alt ? blog.thumb_alt.length: 0  }} {{$t('admin.create.enteredChar')}}</span>
        <input type="text" maxlength="255" v-model="blog.thumb_alt">
      </div>
      <div v-if="false" class="input-wrapper">
        <span class="title">Thumb name: (1 - 255 characters)  {{ blog.thumb_name ? blog.thumb_name.length: 0  }} {{$t('admin.create.enteredChar')}}</span>
        <input type="text" maxlength="255" v-model="blog.thumb_name">
      </div>

      <addImgBlog 
        :mainTitle="$t('admin.create.imgMain')"
        :inputTitle="$t('admin.create.imgMain')+': (0 - 1.5mb)'"
        :oldImage="blog.image"
        @delete="removeImage()"
        @setimg="setMain"
        :clear="clearInput"
      />
      <div v-if="false" class="input-wrapper">
        <span class="title">Image alt: (1 - 255 characters)  {{ blog.img_alt ? blog.img_alt.length: 0  }} {{$t('admin.create.enteredChar')}}</span>
        <input type="text" maxlength="255" v-model="blog.img_alt">
      </div>
      <div v-if="false" class="input-wrapper">
        <span class="title">Image name: (1 - 255 characters)  {{ blog.img_name ? blog.img_name.length: 0  }} {{$t('admin.create.enteredChar')}}</span>
        <input type="text" maxlength="255" v-model="blog.img_name">
      </div>

      <dropdown 
        :defaultText="$t('main.status')"
        :selected="blog.sta_name"
        :list="getBlogStatuses"
        @changeOption="setStatus"
       />
     <div v-if="categoriseApi.length > 0" class="input-wrapper">
          <span class="title">{{$t('admin.create.categories')}}: </span>
          <div class="setCheck">
            <div class="itemCheck" v-for="(caat, index) in categoriseApi" :key="index">
              <input v-if="caat.cat_deleted !=1" type="checkbox"  name="selectCate" :id="caat.cat_id" :value="caat.cat_id" v-model="checkedList" >
              <label v-if="caat.cat_deleted !=1" class='caat' :for="caat.cat_id">{{caat.cat_name}}</label>
            </div>
          </div>
      </div>
      <div class="input-wrapper">
        <span class="title">{{$t('admin.create.content')}}: 
          {{readSize(blog.content)}} 
          {{$t('admin.create.entered')}}, 
          (20MB max)
        </span>
        <vue-editor v-model="blog.content"></vue-editor>
        <div class="preview">
          <div class="title">{{$t('admin.create.preview')}}:</div>
          <div v-html="getPreview" class="content" id="previewId">
          </div>
        </div>
      </div>
      <div class="btn-wrapper">
        <div @click="resetPage" class="buttonCancel">
          {{$t('admin.create.reset')}}
        </div>
        <div @click="edit" class="buttonSend">
          {{$t('admin.create.edit')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { baseURL, api} from '@/api'
import blogItem from '@/components/blog/blogItem.vue'
import { VueEditor } from "vue2-editor";
import dropdown from "../inputcomponents/drop.vue"
import search from "../inputcomponents/search.vue"
import { mapGetters } from 'vuex';
import LocalePicker from '../LocalePickerForm.vue'
import addImgBlog from './addImgBlog.vue';
import helperB from "@/js/helperByteCalc.js"

export default {
  name: 'editBlog',
  components: {
    blogItem,
    VueEditor,
    dropdown,
    LocalePicker,
    search,
    addImgBlog,
  },
  data () {
    return {
      blogs: [],
      offset: 0,
      count: 6,
      more: true,
      blog: null,
      oldBlog: null,
      required: {
        title: null,
        description: null,
        date: null,
        readTime: null,
        author: null,
        content: null,
        link: null,
      },
      createDuplicate: false,
      new_locale: null,
      resetSearch: false,
/**
 * for remove locales of dropdown for select duplicate
 */
      removeLocale: null,
      locationChanges: null,

      categoriseApi: null,
       checkedList:[],
       searchParam:'',

       production: false,
       path_prefix: null,
       clearInput: false


    }
  },
  activated () {
    // this.getBlogs()
    this.resetSearch = !this.resetSearch
    this.searchParam = ''
    this.getBlogsN()
    // this.resetSearch = !this.resetSearch
    this.production = process.env.NODE_ENV === 'production'
    this.path_prefix = window.__blog_path_prefix // ovo zavisi kako ce bek da 
  },
  methods: {
    setMain(e){
      this.blog.image= e
    },
    setThumb(e) {
      this.blog.thumb = e
    },
    validate(evt) {
      var theEvent = evt || window.event;

      // Handle paste
      if (theEvent.type === 'paste') {
          key = event.clipboardData.getData('text/plain');
      } else {
      // Handle key press
          var key = theEvent.keyCode || theEvent.which;
          key = String.fromCharCode(key);
      }
      var regex = /[0-9-]|\./;
      if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
      }
    },
    getBlogsN () {
      let params = {}

        if (this.searchParam !== '') {
            params.search = this.searchParam
            
        }
        params.count= this.count
        params.offset= this.blogs.length
        // params.locale= this.$i18n.locale

        api.getAdminBlogs(params).then(res => {
          for (var i = 0; i < res.data.data.length; i++) {
            this.blogs.push(res.data.data[i])
          }
          if (res.data.count <= this.blogs.length ) {
            this.more = false
          }else {
            this.more = true
          }
      }).catch(e => {
        console.log(e);
      })

    },
    resetPage () {
      this.oldBlog.link = '/blog/' + this.oldBlog.link
      this.selectBlog(this.oldBlog)
      this.clearInput= !this.clearInput
    },
    selectBlog (blog) {
      this.categoriseApi= []
      this.setCategory()
      this.locationChanges = null
      let params = {
        slug: blog.link.substring(6, blog.link.length),
        locale: blog.locale,
        id: blog.id
      }
      api.getAdminBlog(params).then(res => {
        this.blog = JSON.parse(JSON.stringify(res.data.data))
        this.blog.status =  res.data.data.sta_id
        this.blog.delete_image = false
        this.blog.date = this.blog.date.substring(0, 10)
        this.oldBlog = JSON.parse(JSON.stringify(res.data.data))
        this.oldBlog.delete_image = false
        this.oldBlog.date = this.oldBlog.date.substring(0, 10)
        this.oldBlog.status =  res.data.data.sta_id
        this.locationChanges = this.getNameOfLang[this.blog.locale]
        this.createDuplicate = false

        this.removeLocale = this.blog.all_locales
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.clickEl()

      }).catch(e => {
        console.log(e);
      })
    },
    edit () {
      if (!this.checkAllParams()) {
        return
      }
      let changes = new FormData()
      for (var variable in this.blog) {
        if (Object.prototype.hasOwnProperty.call(this.blog, variable)) {
           if((variable === 'thumb' && this.blog[variable] === null) 
           || (variable === 'image' && this.blog[variable] === null)
           || variable === 'categories' 
           || variable === 'available_locales'
           || variable === 'all_locales') {
                  console.log('')
           } else if (this.blog[variable] !== this.oldBlog[variable] && variable === 'content') {

            if(helperB.createBlob(this.blog[variable]) > 20 * (1024 * 1024)){
              alert('Error editing blog, content is too big ')
              return
            }
           
            changes.append(variable, this.blog[variable])
          }
           else if (this.blog[variable] !== this.oldBlog[variable]) {
            // changes[variable] = this.blog[variable]
            changes.append(variable, this.blog[variable])
          }
        }
      }

      if(this.blog.categories === null && this.checkedList.length > 0){
        changes.append('categories', JSON.stringify(this.checkedList))
      }else if(this.blog.categories === null && this.checkedList.length === 0) {
        console.log('')
      }else{
        changes.append('categories', JSON.stringify(this.checkedList))
      }
      
      if (changes.entries().next().done) {
        return
      }
      changes.append('id', this.blog.id)
      api.editBlog(changes).then(() => {
        
        alert('Blog edited successfuly')
        this.removeImage()
        this.removeImageThumb()
        this.blog = null
        this.oldBlog = null

        // reset params
        this.returnOnList()
        window.scrollTo({top: 0, behavior: 'smooth'});
      }).catch(e => {
        alert('Error editing blog ' + e.response.data.message)
      })
    },
    checkAllParams () {
      for (var variable in this.required) {
        if (this.blog[variable] === null || this.blog[variable] === '') {
          alert(this.getName(variable) + ' is missing!')
          return false
        }
      }
      return true
    },
    getName (variable) {
      if (variable === 'img_alt') {
        return 'image alt'
      }
      if (variable === 'img_name') {
        return 'image name'
      }
      if (variable === 'readTime') {
        return 'minutes to read'
      }
       if (variable === 'thumb') {
        return 'thumb image'
      }
      if (variable === 'thumb_name') {
        return 'thumb name'
      }
      if (variable === 'thumb_alt') {
        return 'thumb alt'
      }
      return variable
    },
    removeImage () {
      this.blog.delete_image = true
      this.blog.image = null
    },
    removeImageThumb () {
      this.blog.delete_thumb = true
      this.blog.thumb = null
    },
    setStatus(e) {
      this.blog.status = e.id
      this.blog.sta_id = e.id
      this.blog.sta_name = e.label
    },
    setLocal(e){
      this.new_locale = e

    },
    createNew () {
      if(!this.new_locale) {
        alert('you have to SELECT LOCALE')
        return
      }
      let params = {
        slug: this.blog.link +'_'+this.new_locale,
        new_locale: this.new_locale,
        id:this.blog.id
      }
   
      api.duplicateBlog(params,params.id).then(res => {
   
        this.selectBlog ({
          link:'/blog/'+res.data.data.link, 
          locale: res.data.data.locale, 
          id:res.data.data.id 
        })
      }).catch(e => {
        console.log(e);
      })


    },
    search(e) {
      this.searchParam = e
      this.offset = 0
      this.blogs = []

      this.getBlogsN()

    },
    async setCategory(){
     await api.getCategories().then(res => {
        this.categoriseApi = res.data.data
      }).catch(e => {
        console.log(e)
      })
    },
    clickEl(){
      this.checkedList=[]
      for (let i = 0; i < this.blog?.categories?.length; i++) {
        console.log('setcat')
            setTimeout(() => {  
              console.log(document.getElementById(this.blog.categories[i].cat_id))         
                document.getElementById(this.blog.categories[i].cat_id).click();    
            }, 25);
          }
        },
    returnOnList (){
      this.oldBlog = null
      this.blog = null
      this.resetSearch = !this.resetSearch
      this.searchParam = ''
      this.offset = 0
      this.blogs = []
      setTimeout(() => {
        this.getBlogsN()
      }, 10);
     
    },
    readSize(content){
    return  helperB.byteToMB( helperB.createBlob(content))
    }
  },
  watch: {
    clearInput:{
      handler(){
        // this.categoriseApi= []
      }
    }
  },
  computed: {
    ...mapGetters(['getBlogStatuses', 'getNameOfLang']),
    getPreview () {
      return this.blog.content.replaceAll('&lt;', '<').replaceAll('&gt;','>')
    },
    baseURL () {
      return baseURL.replace('/blog-template/api/', '')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/adminBlogForm';
.colorBTN{
  color: var(--btn-color);
}

</style>
