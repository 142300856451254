export default {
    createBlob(data) {
        // return new Blob([data], { type: 'text/plain' });
        return new Blob([data], { type: 'application/octet-stream' }).size;
    },
    byteToMB(byteData) {
        if (byteData <= 1024) {
            return byteData + ' bytes'
        } else if (byteData > 1024 && byteData < (1024 * 1024)) {
            return (byteData / 1024).toFixed(2) + ' kB'
        }
        return (byteData / (1024 * 1024)).toFixed(2) + ' MB'
    }
}